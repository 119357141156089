import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IColumn, IGridProps, IGridRefreshInput } from "../../../components/grid";
import { IBloc } from "../../../ioc";
import { Region, ISortBy, User } from "../../../models";
import { DialogType } from "../../../models/dialog";
import {
  DialogServiceSymbol,
  IDialogService,
  ICountryService,
  CountryServiceSymbol,
  NavigationServiceSymbol,
  INavigationService,
  UserServiceSymbol,
  IUserService,
  IRegionService,
  RegionServiceSymbol,
} from "../../../services";
import {
  ICountryStore,
  CountryStoreSymbol,
  UserStoreSymbol,
  IUserStore,
  IRegionStore,
  RegionStoreSymbol  
} from "../../../stores";
import { timeElapsed } from "../../../utils/time-elapsed";

export interface IRegionProps {
  start?: number;
  limit?: number;
  filter?: any;
  sortBy?: ISortBy;
}

export interface IRegionBloc extends IBloc<any> {
  error: string;
  gridData: IGridProps;
  onRegionEdit: (uid: string) => (e: any) => void;
  onAddNewRegion: () => (e: any) => void;
}

@injectable()
export class RegionBloc implements IRegionBloc {
  @inject(DialogServiceSymbol)
  private readonly dialogService!: IDialogService;
  @inject(RegionServiceSymbol)
  private readonly regionService!: IRegionService;
  @inject(RegionStoreSymbol)
  private readonly regionStore!: IRegionStore;
  @inject(CountryStoreSymbol)
  private readonly countryStore!: ICountryStore;
  @inject(CountryServiceSymbol)
  private readonly countryService!: ICountryService;
  @inject(NavigationServiceSymbol)
  private readonly navigationService!: INavigationService;
  @inject(UserServiceSymbol)
  private readonly userService!: IUserService;
  @inject(UserStoreSymbol)
  private readonly userStore!: IUserStore;

  @observable private _loading: boolean = true;
  @observable private _error: string = "";
  @observable private _start: number = 0;
  @observable private _limit: number = 10;
    @observable private _admins: User[] = []

  @observable
  private regionColumns: IColumn<Region>[] = [
    {
      field: "country",
      headerName: "Country",
      valueGetter: ({ row }) => this.countryStore.find(row.countryUid)?.country  ?? "",
    },
    {
      field: "description",
      headerName: "Description",
      valueGetter: ({ row }) => row.description ?? "",
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: ({ row }) => row.status ?? "",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      valueGetter: ({ row }) => timeElapsed(row.createdAt) ?? "",
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      valueGetter: ({ row }) => timeElapsed(row.updatedAt) ?? "",
    },
  ];

  constructor() {
    makeObservable(this);
  }

  @computed
  public get error(): string {
    return this._error;
  }

  @computed
  public get gridData(): IGridProps {
    return {
      error: this._error,
      loading: this._loading,
      columns: this.regionColumns,
      data: this.regionStore.data,
      count: this.regionStore.count,
      page: Math.floor(this._start / this._limit),
      pageSize: this._limit,
      collapsibleRowItemActions: this.onCollapsibleRowItemAction,
      collapsibleRowItemValue: this.collapsibleRowItemValue,
      onRefresh: this.handleRefresh,
    };
  }

  public mount(props: IRegionProps): void {
    this.refresh(props);
  }

  private onCollapsibleRowItemAction =
    (item: keyof Region, val: any) => (e: any) => {
      e.preventDefault();
      switch (item) {
        case "pricelistUid":
          this.navigationService.navigate(`/pricelists?uid=${val}`);
          break;
        default:
          break;
      }
    };

  private collapsibleRowItemValue = (
    item: keyof Region,
    val: any
  ): string | null => {
    let value: string | null = null;
    switch (item) {
      case "responsiblePartyUid":
        value = this._admins.filter((_) => _.uid === val)[0]?.email ?? null;
        break;
      case "radius":
        value = `${val} m`
        break  
      case "zipCodes":{
        let zipCodes = ''
        if(!Array.isArray(val)) console.error('zipCodes should be an array')
        val.forEach((code, idx) => {
          zipCodes = idx === 0 ? `${code}` : `${zipCodes}, ${code}`
        })
        value = zipCodes
        break  
      }
      default:
        break;
    }
    return value;
  };

  public onRegionEdit = (uid: string) => (e: any) => {
    this.dialogService.open({ type: DialogType.RegionUpdate, props: { uid } });
  };
  public onAddNewRegion = () => (e: any) => {
    this.dialogService.open({ type: DialogType.RegionAdd });
  };

  private handleRefresh = ({
    page,
    pageSize,
    filter,
    sortBy,
  }: IGridRefreshInput) => {
    const props: IRegionProps = {
      start: page * pageSize,
      limit: pageSize,
      filter,
      sortBy,
    };
    this.refresh(props);
  };

  private async refresh({
    start,
    limit,
    sortBy,
    filter,
  }: IRegionProps): Promise<void> {
    runInAction(() => {
      if (start != undefined) {
        this._start = start;
      }
      if (limit != undefined) {
        this._limit = limit;
      }
    });

    if (!filter) {
      filter = "";
    }
    try {
      await this.regionService.fetch(start, limit, filter, sortBy);
      await this.userService.fetch(undefined, undefined, `role="admin"`);
      await this.countryService.fetch();
    } catch (err: any) {
      console.error(err)
      runInAction(() => {
        this._error = err.message;
      });
    } finally {
      runInAction(() => {
        this._loading = false;
        this._admins = this.userStore.data;
      });
    }
  }
}
