import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IColumn, IGridProps, IGridRefreshInput } from "../../../components/grid";
import { IBloc } from "../../../ioc";
import { PriceList, ISortBy } from "../../../models";
import { DialogType } from "../../../models/dialog";
import {
  DialogServiceSymbol,
  IDialogService,
  IPriceListService,
  PriceListServiceSymbol,
  INavigationService,
  NavigationServiceSymbol
} from "../../../services";
import { IPriceListStore, PriceListStoreSymbol } from "../../../stores";
import { timeElapsed } from '../../../utils/time-elapsed'

export interface IPricelistsProps {
  start?: number;
  limit?: number;
  filter?: any;
  sortBy?: ISortBy;
}

export interface IPricelistsBloc extends IBloc<any> {
  error: string;
  gridData: IGridProps;
  onPricelistEdit: (uid: string) => (e: any) => void;
  onAddNewPricelist: () => (e: any) => void
}

@injectable()
export class PricelistsBloc implements IPricelistsBloc {

  @inject(DialogServiceSymbol)
  private readonly dialogService!: IDialogService;
  @inject(PriceListStoreSymbol)
  private readonly priceListStore!: IPriceListStore;
  @inject(PriceListServiceSymbol)
  private readonly pricelistService!: IPriceListService;
  @inject(NavigationServiceSymbol)
  private readonly navigationService!: INavigationService;

  @observable private _loading: boolean = true;
  @observable private _error: string = "";
  @observable private _start: number = 0;
  @observable private _limit: number = 10;

  @observable
  private pricelistsColumns: IColumn<PriceList>[] = [
    {
      field: "currency",
      headerName: "Currency",
      valueGetter: ({ row }) => row.currency ?? "",
    },
    {
      field: "description",
      headerName: "Description",
      valueGetter: ({ row }) => row.description ?? "",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      valueGetter: ({ row }) => timeElapsed(row.createdAt) ?? "",
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      valueGetter: ({ row }) => timeElapsed(row.updatedAt) ?? "",
    },
  ];

  constructor() {
    makeObservable(this);
  }

  @computed
  public get error(): string {
    return this._error;
  }

  @computed
  public get gridData(): IGridProps {
    return {
      error: this._error,
      loading: this._loading,
      columns: this.pricelistsColumns,
      data: this.priceListStore.data,
      count: this.priceListStore.count,
      page: Math.floor(this._start / this._limit),
      pageSize: this._limit,
      onRefresh: this.handleRefresh,
    };
  }

  public mount(props: IPricelistsProps): void {
    const uidParam = this.navigationService.getQueryParam('uid', String, true)
    if(uidParam) props.filter =`uid="${uidParam}"`;
    this.refresh(props);
  }

  public onPricelistEdit = (uid: string) => (e: any) => {
    this.dialogService.open({ type: DialogType.PriceListUpdate, props: { uid } });
  };

  public onAddNewPricelist = () => (e: any) => {
    this.dialogService.open({ type: DialogType.PriceListAdd });
  }

  private handleRefresh = ({
    page,
    pageSize,
    filter,
    sortBy,
  }: IGridRefreshInput) => {
    const props: IPricelistsProps = {
      start: page * pageSize,
      limit: pageSize,
      filter,
      sortBy,
    };
    this.refresh(props);
  };

  private async refresh({
    start,
    limit,
    sortBy,
    filter,
  }: IPricelistsProps): Promise<void> {
    runInAction(() => {
      if (start != undefined) {
        this._start = start;
      }
      if (limit != undefined) {
        this._limit = limit;
      }
    });

    if (!filter) {
      filter = "";
    }
    try {
      await this.pricelistService.fetch(start, limit, filter, sortBy);
    } catch (err: any) {
      console.error(err)
      runInAction(() => {
        this._error = err.message;
      });
    } finally {
      runInAction(() => {
        this._loading = false;
      });
    }
  }
}
