import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IColumn, IGridProps, IGridRefreshInput } from "../../../components/grid";
import { IBloc } from "../../../ioc";
import { Country, ISortBy, User } from "../../../models";
import { DialogType } from "../../../models/dialog";
import {
  DialogServiceSymbol,
  IDialogService,
  ICountryService,
  CountryServiceSymbol,
  NavigationServiceSymbol,
  INavigationService,
  AttachmentServiceSymbol,
  IAttachmentService,
  UserServiceSymbol,
  IUserService,
} from "../../../services";
import {
  ICountryStore,
  CountryStoreSymbol,
  UserStoreSymbol,
  IUserStore,
} from "../../../stores";
import { timeElapsed } from "../../../utils/time-elapsed";

export interface ICountryProps {
  start?: number;
  limit?: number;
  filter?: any;
  sortBy?: ISortBy;
}

export interface ICountryBloc extends IBloc<any> {
  error: string;
  gridData: IGridProps;
  onCountryEdit: (uid: string) => (e: any) => void;
  onAddNewCountry: () => (e: any) => void;
}

@injectable()
export class CountryBloc implements ICountryBloc {
  @inject(DialogServiceSymbol)
  private readonly dialogService!: IDialogService;
  @inject(CountryStoreSymbol)
  private readonly countryStore!: ICountryStore;
  @inject(CountryServiceSymbol)
  private readonly countryService!: ICountryService;
  @inject(NavigationServiceSymbol)
  private readonly navigationService!: INavigationService;
  @inject(AttachmentServiceSymbol)
  private readonly attachmentService!: IAttachmentService;
  @inject(UserServiceSymbol)
  private readonly userService!: IUserService;
  @inject(UserStoreSymbol)
  private readonly userStore!: IUserStore;

  @observable private _loading: boolean = true;
  @observable private _error: string = "";
  @observable private _start: number = 0;
  @observable private _limit: number = 10;
  @observable private _admins: User[] = []

  @observable
  private countryColumns: IColumn<Country>[] = [
    {
      field: "country",
      headerName: "Country",
      valueGetter: ({ row }) => row.country ?? "",
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: ({ row }) => row.status ?? "",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      valueGetter: ({ row }) => timeElapsed(row.createdAt) ?? "",
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      valueGetter: ({ row }) => timeElapsed(row.updatedAt) ?? "",
    },
  ];

  constructor() {
    makeObservable(this);
  }

  @computed
  public get error(): string {
    return this._error;
  }

  @computed
  public get gridData(): IGridProps {
    return {
      error: this._error,
      loading: this._loading,
      columns: this.countryColumns,
      data: this.countryStore.data,
      count: this.countryStore.count,
      page: Math.floor(this._start / this._limit),
      pageSize: this._limit,
      collapsibleRowItemActions: this.onCollapsibleRowItemAction,
      collapsibleRowItemValue: this.collapsibleRowItemValue,
      onRefresh: this.handleRefresh,
    };
  }

  public mount(props: ICountryProps): void {
    this.refresh(props);
  }

  private onCollapsibleRowItemAction =
    (item: keyof Country, val: any) => (e: any) => {
      e.preventDefault();
      switch (item) {
        case "pricelistUid":
          this.navigationService.navigate(`/pricelists?uid=${val}`);
          break;
        case "doctorTemplateUid":
        case "patientTemplateUid":
        case "childTemplateUid":
          this.goToAttachment(val);
          break;
        default:
          break;
      }
    };

  private async goToAttachment(uid: string): Promise<void> {
    const publicUrl = await this.attachmentService.fetchPublicUrl(uid);
    window.location.href = publicUrl;
  }

  private collapsibleRowItemValue = (item: keyof Country, val: any): string | null => {
    let value: string | null = null;
    switch (item) {
      case "responsiblePartyUid":
        value = this._admins.filter(_ => _.uid === val)[0]?.email ?? null
        break;
      default:
        break;
    }
    return value;
  }

  public onCountryEdit = (uid: string) => (e: any) => {
    this.dialogService.open({ type: DialogType.CountryUpdate, props: { uid } });
  };
  public onAddNewCountry = () => (e: any) => {
    this.dialogService.open({ type: DialogType.CountryAdd });
  };

  private handleRefresh = ({
    page,
    pageSize,
    filter,
    sortBy,
  }: IGridRefreshInput) => {
    const props: ICountryProps = {
      start: page * pageSize,
      limit: pageSize,
      filter,
      sortBy,
    };
    this.refresh(props);
  };

  private async refresh({
    start,
    limit,
    sortBy,
    filter,
  }: ICountryProps): Promise<void> {
    runInAction(() => {
      if (start != undefined) {
        this._start = start;
      }
      if (limit != undefined) {
        this._limit = limit;
      }
    });

    if (!filter) {
      filter = "";
    }
    try {
      await this.countryService.fetch(start, limit, filter, sortBy);
      await this.userService.fetch(undefined, undefined, `role="admin"`)
    } catch (err: any) {
      console.error(err)
      runInAction(() => {
        this._error = err.message;
      });
    } finally {
      runInAction(() => {
        this._loading = false;
        this._admins = this.userStore.data
      });
    }
  }
}
