import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IBloc } from "../../../ioc";
import {
  IConsultationService,
  ConsultationServiceSymbol,
  IUserService,
  UserServiceSymbol,
} from "../../../services";
import {
  IConsultationStore,
  ConsultationStoreSymbol,
  IUserStore,
  UserStoreSymbol,
} from "../../../stores";
import { DoctorLocation } from "../../../models";

export interface IWaitingConsultationMapBloc extends IBloc<any> {
  mapCenter: { lat: number; lng: number };
  markerData: IMarkerItem[];
}

export interface IMarkerItem {
  consultationUid: string;
  medicalCare: string;
  patient: string;
  patientUid: string;
  location: { lat: number; lng: number };
  createdAt: Date;
}

export interface IDoctorLocationItem {
  location: { lat: number; lng: number };
  radius: number;
}

@injectable()
export class WaitingConsultationMapBloc implements IWaitingConsultationMapBloc {
  @inject(ConsultationServiceSymbol)
  private readonly consultationService!: IConsultationService;
  @inject(ConsultationStoreSymbol)
  private readonly consultationStore!: IConsultationStore;
  @inject(UserServiceSymbol)
  private readonly userService!: IUserService;
  @inject(UserStoreSymbol)
  private readonly userStore!: IUserStore;

  @observable public isLoaded: boolean;

  constructor() {
    makeObservable(this);
    this.isLoaded = false;
  }

  @computed
  public get mapCenter(): { lat: number; lng: number } {
    const len = this.consultationStore.data.length;
    let lat: number = 0;
    let lng: number = 0;
    this.consultationStore.data.forEach((item) => {
      lat += item.coordinate.latitude;
      lng += item.coordinate.longitude;
    });
    return { lat: lat / len, lng: lng / len };
  }

  @computed
  public get markerData(): IMarkerItem[] {
    return this.consultationStore.data.map((item) => {
      return {
        consultationUid: item.uid,
        medicalCare: item.medicalCare,
        patient: `${item.patient.firstName} ${item.patient.lastName}`,
        patientUid: item.patient.uid,
        location: {
          lat: item.coordinate.latitude,
          lng: item.coordinate.longitude,
        },
        createdAt: item.createdAt,
      };
    });
  }

  @computed
  public get activeDoctorLocations(): IDoctorLocationItem[] {
    const doctorLocation = this.userStore.data
      .filter((x) => x.doctor && x.doctor.doctorLocation)
      .map((x) => x.doctor?.doctorLocation as DoctorLocation);
    return doctorLocation.map((x) => {
      return {
        location: {
          lat: x.coordinate.latitude,
          lng: x.coordinate.longitude,
        },
        radius: Math.floor(x.radius * 1609.344),
      };
    });
  }

  public mount(): void {
    this.consultationService.fetch(undefined, undefined, 'state="waiting"');
    this.userService.fetch(undefined, undefined, 'role="doctor"&&isDoctorActive');
    runInAction(() => {
      this.isLoaded = true;
    });
  }
}
