import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IBloc } from "../ioc";
import { ObservableValidateAutomate } from "../utils/observable-validation-automate";
import {
  ICountryService,
  CountryServiceSymbol,
  DialogServiceSymbol,
  IDialogService,
  UserServiceSymbol,
  IUserService,
  PriceListServiceSymbol,
  IPriceListService,
  AttachmentServiceSymbol,
  IAttachmentService,
} from "../services";
import { PatchCountryInput, PriceList, User } from "../models";
import { IUploader } from "../components/file-uploader";
import { ISelect } from "../components/select-input";
import {
  UserStoreSymbol,
  IUserStore,
  PriceListStoreSymbol,
  IPriceListStore,
  CountryStoreSymbol,
  ICountryStore,
  AttachmentStoreSymbol,
  IAttachmentStore,
} from "../stores";
import { AvailabilityStatus } from "../models";

interface IFormTextField {
  value?: string;
  error: string | null | undefined;
  onChange: (v: string | any) => void;
}

interface ICountryFormViewData {
  country: IFormTextField;
  status: ISelect;
  pricelist: ISelect;
  emergencyPhone: IFormTextField;
  doctorTemplate: IUploader;
  patientTemplate: IUploader;
  childTemplate: IUploader;
  responsibleParty: ISelect;
  stripePublicKey: IFormTextField;
  stripeSecretKey: IFormTextField;
  stripeWebhookSecret: IFormTextField;
}

export interface ICountryUpdateProps {
  uid: string;
  onSubmit?: () => void;
}

export interface ICountryUpdateBloc extends IBloc<any> {
  formData: ICountryFormViewData;
  loading: boolean;
  loadingBtn: boolean;
  error: string | null;
  onSubmit(e?: any): void;
  onCancel(e?: any): void;
  onClose(e?: any): void;
}

const statuses: [string, string][] = [
  [AvailabilityStatus.Available, "Available"],
  [AvailabilityStatus.ComingSoon, "Coming Soon"],
  [AvailabilityStatus.Unavailable, "Unavailable"],
];

@injectable()
export class CountryUpdateBloc implements ICountryUpdateBloc {
  @inject(CountryServiceSymbol) countryService!: ICountryService;
  @inject(DialogServiceSymbol) dialogService!: IDialogService;
  @inject(UserServiceSymbol) userService!: IUserService;
  @inject(PriceListServiceSymbol) pricelistService!: IPriceListService;
  @inject(UserStoreSymbol) userStore!: IUserStore;
  @inject(PriceListStoreSymbol) priceListStore!: IPriceListStore;
  @inject(CountryStoreSymbol) countryStore!: ICountryStore;
  @inject(AttachmentStoreSymbol) attachmentStore!: IAttachmentStore;
  @inject(AttachmentServiceSymbol) attachmentService!: IAttachmentService;

  @observable private _uid: string | null = null;

  @observable private _loading: boolean = true;
  @observable private _loadingBtn: boolean = false;
  @observable private _error: string | null = null;

  @observable private _pricelists: PriceList[] = [];
  @observable private _admins: User[] = [];

  @observable private prevDoctorTplUid: string = "";
  @observable private prevPatientTplUid: string = "";
  @observable private prevChildTplUid: string = "";

  @observable
  private _validator = new ObservableValidateAutomate(PatchCountryInput);

  constructor() {
    makeObservable(this);
  }

  mount({ uid }: ICountryUpdateProps): void {
    if (this._uid === uid || !uid) return;
    const countryData = this.countryStore.find(uid);
    if (!countryData)
      throw new Error(`Could not find a country by uid: ${uid}`);

    this.refreshSelectableFields();

    runInAction(() => (this._uid = uid));

    const {
      country,
      status,
      pricelistUid: pricelist,
      emergencyPhone,
      doctorTemplateUid,
      patientTemplateUid,
      childTemplateUid,
      responsiblePartyUid: responsibleParty,
      stripePublicKey,
      stripeSecretKey,
      stripeWebhookSecret,
    } = countryData;

    runInAction(() => {
      this.prevDoctorTplUid = doctorTemplateUid;
      this.prevPatientTplUid = patientTemplateUid;
      this.prevChildTplUid = childTemplateUid;
    });

    this.setPrevAttachmentsIntoStore([
      ...new Set([doctorTemplateUid, patientTemplateUid, childTemplateUid]),
    ]);

    this._validator.reset().update({
      country,
      status,
      pricelist,
      emergencyPhone,
      responsibleParty,
      stripePublicKey,
      stripeSecretKey,
      stripeWebhookSecret,
    //   doctorTemplate: null,
    //   patientTemplate: null,
    //   childTemplate: null,
    });

    runInAction(() => (this._loading = false));
  }

  @computed
  public get formData(): ICountryFormViewData {
    return {
      country: {
        value: this._validator.values.country ?? "",
        error: this._validator.errors.country,
        onChange: this._validator.update.country,
      },
      status: {
        id: "status",
        label: "Availability Status",
        values: statuses,
        value: this._validator.values.status ?? "",
        error: this._validator.errors.status,
        onChange: this._validator.update.status,
      },
      pricelist: {
        id: "pricelist",
        label: "Pricelist",
        values: this.pricelistSelectableInput,
        value: this._validator.values.pricelist ?? "",
        error: this._validator.errors.pricelist,
        onChange: this._validator.update.pricelist,
      },
      emergencyPhone: {
        value: this._validator.values.emergencyPhone ?? "",
        error: this._validator.errors.emergencyPhone,
        onChange: this._validator.update.emergencyPhone,
      },
      doctorTemplate: {
        id: "doctorTemplate",
        label: "Upload Doctor Template",
        accept: ".pdf",
        defaultFilename:
          this.attachmentStore.find(this.prevDoctorTplUid)?.filename ?? "",
        value: this._validator.values.doctorTemplate,
        errorText: this._validator.errors.doctorTemplate,
        onChange: this._validator.update.doctorTemplate,
      },
      patientTemplate: {
        id: "patientTemplate",
        label: "Upload Patient Template",
        accept: ".pdf",
        defaultFilename:
          this.attachmentStore.find(this.prevPatientTplUid)?.filename ?? "",
        value: this._validator.values.patientTemplate,
        errorText: this._validator.errors.patientTemplate,
        onChange: this._validator.update.patientTemplate,
      },
      childTemplate: {
        id: "childTemplate",
        label: "Upload Child Template",
        accept: ".pdf",
        defaultFilename:
          this.attachmentStore.find(this.prevChildTplUid)?.filename ?? "",
        value: this._validator.values.childTemplate,
        errorText: this._validator.errors.childTemplate,
        onChange: this._validator.update.childTemplate,
      },
      responsibleParty: {
        id: "responsibleParty",
        label: "Responsible Party",
        values: this.adminSelectableInput,
        value: this._validator.values.responsibleParty ?? "",
        error: this._validator.errors.responsibleParty,
        onChange: this._validator.update.responsibleParty,
      },
      stripePublicKey: {
        value: this._validator.values.stripePublicKey ?? "",
        error: this._validator.errors.stripePublicKey,
        onChange: this._validator.update.stripePublicKey,
      },
      stripeSecretKey: {
        value: this._validator.values.stripeSecretKey ?? "",
        error: this._validator.errors.stripeSecretKey,
        onChange: this._validator.update.stripeSecretKey,
      },
      stripeWebhookSecret: {
        value: this._validator.values.stripeWebhookSecret ?? "",
        error: this._validator.errors.stripeWebhookSecret,
        onChange: this._validator.update.stripeWebhookSecret,
      },
    };
  }
  @computed
  public get loading(): boolean {
    return this._loading;
  }
  @computed
  public get loadingBtn(): boolean {
    return this._loadingBtn;
  }
  @computed
  public get error(): string | null {
    return this._error;
  }

  @computed
  private get pricelistSelectableInput(): [string, string][] {
    return this._pricelists.map((pricelist) => {
      return [
        pricelist.uid,
        `${pricelist.currency} - ${pricelist.description}`,
      ];
    });
  }
  @computed
  private get adminSelectableInput(): [string, string][] {
    return this._admins.map((admin) => {
      return [admin.uid, admin.email];
    });
  }
  @computed
  public get admins(): User[] {
    return this._admins;
  }

  private async setPrevAttachmentsIntoStore(uids: string[]): Promise<void> {
    uids.map(async (uid) => {
      let attachment = this.attachmentStore.find(uid);
      if (!attachment) await this.attachmentService.fetchAttachment(uid);
    });
  }

  public onSubmit = async (e: any) => {
    e.preventDefault();
    if (!this._uid) return;
    if (!this._validator.validate()) return;

    runInAction(() => (this._loadingBtn = true));

    try {
      const data = this._validator.read();
      await this.countryService.updateCountry(this._uid, data);
      this.dialogService.close();
    } catch (err: any) {
      throw new Error(err.message);
    } finally {
      runInAction(() => (this._loadingBtn = false));
      this._validator.reset();
    }
  };

  public onCancel = (e?: any) => {
    this.dialogService.close();
  };

  public onClose = (e?: any) => {
    this.dialogService.close();
  };

  private async refreshSelectableFields() {
    try {
      await this.userService.fetch(undefined, undefined, `role="admin"`);
      await this.pricelistService.fetch();
    } catch (e: any) {
      runInAction(() => {
        this._error = e.message;
      });
    } finally {
      runInAction(() => {
        this._pricelists = this.priceListStore.data;
        this._admins = this.userStore.data;
      });
      if (this._pricelists.length === 0 || this._admins.length === 0) {
        runInAction(() => {
          this._error =
            "Error occured. Fetched empty data for obligatory select fields";
        });
      }
    }
  }
}
