import {injectable} from "inversify";
import {observable, action, makeObservable, computed} from 'mobx';
import {User} from '../models';

export interface IMeUserStore {
    readonly user: User | null;
    set(user: User): void;
    clean(): void;
}

export const MeUserStoreSymbol = Symbol.for('IMeUserStore')

@injectable()
export class MeUserStore implements IMeUserStore {

    @observable
    public _user: User | null;

    public constructor() {
        this._user = null;
        makeObservable(this)
    }

    @computed get user(): User | null {
        return this._user;
    }

    @action
    public set(user: User): void {
        if (this._user !== user) {
            this._user = user;
        }
    }

    @action
    public clean(): void {
        if (this._user) {
            this._user = null;
        }
    }
}