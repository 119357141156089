import { inject, injectable } from "inversify";
import { IAttachmentApi, AttachmentApiSymbol } from "../api";
import { IAttachmentStore, AttachmentStoreSymbol } from "../stores";

export interface IAttachmentService {
  fetchAttachment(uid: string): Promise<void>;
  fetchPublicUrl(uid: string): Promise<string>
}

export const AttachmentServiceSymbol = Symbol.for("IAttachmentService");

@injectable()
export class AttachmentService implements IAttachmentService {
  @inject(AttachmentApiSymbol)
  private attachmentApi!: IAttachmentApi;

  @inject(AttachmentStoreSymbol)
  private attachmentStore!: IAttachmentStore;


  public async fetchAttachment(uid: string): Promise<void> {
    const attachment = await this.attachmentApi.getAttachment(uid);
    this.attachmentStore.add(attachment);
  }

  public async fetchPublicUrl(uid: string): Promise<string>{
    const { publicUrl } = await this.attachmentApi.getAttachment(uid);
    return publicUrl
  }
}
