import {
    validate,
    presence,
    length,
    alphaNumeric,
    inclusion,
    type,
  } from "@ts-awesome/validate";
  import {conditional} from '@ts-awesome/validate/dist/validators/conditional'
  import { readable } from "@ts-awesome/model-reader";
  import { CountryISOList } from '../../utils/countries'
  import { AvailabilityStatuses } from "../country";
  import { file } from "../../utils/file-validator";
  
  export class PatchCountryInput {
  
    @validate([presence(), alphaNumeric(false), length({ is: 2 }), inclusion(CountryISOList)])
    @readable(String)
    public country!: string;

    @validate([presence(), alphaNumeric(false), inclusion(AvailabilityStatuses)])
    @readable(String)
    public status!: string;

    @validate([presence()])
    @readable(String)
    public pricelist!: string;

    @validate([presence()])
    @readable(String)
    public emergencyPhone!: string;

    @validate([conditional({check: [type("object"), file()], when(model: PatchCountryInput) {
      return model.doctorTemplate !== undefined
    }})])
    @readable(Object, true) 
    public doctorTemplate!: File | null;

    @validate([conditional({check: [type("object"), file()], when(model: PatchCountryInput) {
      return model.patientTemplate !== undefined
    }})])    
    @readable(Object, true) 
    public patientTemplate!: File | null;

    @validate([conditional({check: [type("object"), file()], when(model: PatchCountryInput) {
      return model.childTemplate !== undefined
    }})])    
    @readable(Object, true) 
    public childTemplate!: File | null;

    @validate([presence()])
    @readable(String)
    public responsibleParty!: string;

    @validate([presence()])
    @readable(String)
    public stripePublicKey!: string;

    @validate([presence()])
    @readable(String)
    public stripeSecretKey!: string;

    @validate([presence()])
    @readable(String)
    public stripeWebhookSecret!: string;

  }