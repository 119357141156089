import { inject, injectable } from "inversify";
import { DialogModel } from "../models/dialog";
import { DialogStoreSymbol, IDialogStore } from "../stores";

export interface IDialogService {
  open<TProps>(data: DialogModel<TProps>): void;
  close(): void;
}

export const DialogServiceSymbol = Symbol.for('IDialogService');

@injectable()
export class DialogService implements IDialogService {

  @inject(DialogStoreSymbol)
  private readonly store!: IDialogStore;

  public open<TProps>(data: DialogModel<TProps>): void {
    this.store.set(true, data);
  }

  public close(): void {
    this.store.clean();
  }
}