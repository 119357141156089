import { readable } from "@ts-awesome/model-reader";

export class Attachment {
    @readable(String) public uid!: string;
    @readable(String) public mime!: string;
    @readable(String) public filename!: string;
    @readable(String) public createdBy!: string;
    @readable(Date) public updatedAt!: Date;
    @readable(Date) public createdAt!: Date;
    @readable(String) public publicUrl!: string;
  }