import { inject, injectable } from 'inversify';
import { IFetchFilter, ISortBy, User } from '../models';
import { HttpTransportSymbol, IHttpTransport } from '@ts-awesome/request';
import { DoctorInput } from '../models';

export interface IUserApi {
  getMe(): Promise<User>;
  get(uid: string): Promise<User>;
  list(start?: number, limit?: number, filter?: IFetchFilter, sortBy?: ISortBy): Promise<readonly User[]>;
  count(filter?: IFetchFilter): Promise<number>;
  putDoctor(uid: string, input: Partial<DoctorInput>): Promise<User>;
  patchDoctor(uid: string, input: DoctorInput['approved']): Promise<User>
}

export const UserApiSymbol = Symbol.for('IUserApi');

@injectable()
export class UserApi implements IUserApi {

  @inject(HttpTransportSymbol)
  private readonly http!: IHttpTransport;

  public getMe(): Promise<User> {
    return this.http.get('/api/v1/user/me', {Model: User});
  }

  public get(uid: string): Promise<User> {
    return this.http.get(`/api/v1/user/${uid}`, {Model: User});
  }

  public async list(start?: number, limit?: number, filter?: IFetchFilter, sortBy?: ISortBy): Promise<readonly User[]> {
    const qs: Record<string, string | number> = {};
    if (start) {
      qs.offset = start;
    }
    if (limit) {
      qs.limit = limit;
    }
    if (filter) {
      qs.q = filter;
    }
    if (sortBy) {
      qs.order = Object.keys(sortBy).map(col => `${col}${sortBy[col] === 'desc' ? '-' : '+'}`).join(',');
    }
    return await this.http.get('/api/v1/user', {qs, Model: [User]}) as never;
  }

  public count(filter?: IFetchFilter): Promise<number> {
    const qs: Record<string, string | number | boolean> = {count: true};
    if (filter) {
      qs.q = filter;
    }
    return this.http.get('/api/v1/user', {qs});
  }

  public async putDoctor(uid: string, input: Partial<DoctorInput>): Promise<User> {
    if (!uid) {
      throw new Error('Uid is missing');
    }
    return this.http.put<User>(`/api/v1/user/${uid}/doctor`, {
      body: {...input},
      Model: User
    });
  }

  public async patchDoctor(uid: string, input: DoctorInput['approved']): Promise<User> {
    if (!uid) {
      throw new Error('Uid is missing');
    }
    return this.http.patch<User>(`/api/v1/user/${uid}/doctor`, {
      body: {approved: input},
      Model: User
    });
  }

}
