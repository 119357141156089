import React, { memo, useCallback } from 'react';
import {TextField as MaterialTextField, TextFieldProps}  from '@mui/material';
import { IFormField } from '../models';

export type InputProps<T=string> = IFormField<T> & Omit<TextFieldProps, 'error' | 'onChangeText' | 'onChange' | 'onTextInput'>

export const TextField = memo<InputProps>(props => {
  const {value, error, onChange, style, ...otherProps} = props;
  const handleChange = useCallback((e: any) => onChange?.(e.target.value), [onChange])

  return (
    <MaterialTextField
      error={!!error}
      helperText={error || ''}
      onChange={handleChange}
      value={value ?? ''}
      {...otherProps}
    />
  );
})

