import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IColumn, IGridProps, IGridRefreshInput } from "../../../components/grid";
import { IBloc } from "../../../ioc";
import { ISortBy, User } from "../../../models";
import { IUserService, UserServiceSymbol } from "../../../services";
import { IUserStore, UserStoreSymbol } from "../../../stores";
import { timeElapsed } from "../../../utils/time-elapsed";

export interface IUsersProps {
  start?: number;
  limit?: number;
  filter?: any;
  sortBy?: ISortBy;
}

export interface IPatientsBloc extends IBloc<any> {
  error: string;
  gridData: IGridProps;
}

const patientsColumns: IColumn<User>[] =[{
  field: 'phone',
  headerName: 'Phone'
}, {
  field: 'email',
  headerName: 'Email'
}, {
    field: 'verifiedAt',
    headerName: 'Verified At',
    valueGetter: ({row}) => {
      return row.verifiedAt ? (timeElapsed(row.verifiedAt) || '') : '-';
    }}]

@injectable()
export class PatientsBloc implements IPatientsBloc {

  @inject(UserServiceSymbol)
  private readonly userService!: IUserService;
  @inject(UserStoreSymbol)
  private readonly userStore!: IUserStore;

  @observable private _loading: boolean = true;
  @observable private _error: string = '';
  @observable private _start: number = 0;
  @observable private _limit: number = 10;

  //TODO add filter and sortBy
  
  constructor() {
    makeObservable(this);
  }

  @computed
  public get error(): string {
    return this._error;
  }

  @computed
  public get gridData(): IGridProps {
    return {
      error: this._error,
      loading: this._loading,
      columns: patientsColumns,
      data: this.userStore.data.filter(_ => _.role === 'patient'),
      count: this.userStore.count,
      page: Math.floor(this._start / this._limit),
      pageSize: this._limit,
      onRowClick: this.handleOnRowClick,
      onRefresh: this.handleRefresh,
    }
  }

  public mount(props: IUsersProps): void {
    this.refresh(props);
  }

  private handleRefresh = ({page, pageSize, filter, sortBy}: IGridRefreshInput) => {
    const props: IUsersProps = {
      start: page * pageSize,
      limit: pageSize,
      filter,
      sortBy,
    }
    this.refresh(props);
  }

  private handleOnRowClick = (uid: string) => (e: any) => {}

  private async refresh({start, limit, sortBy, filter}: IUsersProps): Promise<void> {
    runInAction(() => {
      if (start != undefined) {
        this._start = start;
      }
      if (limit != undefined) {
        this._limit = limit;
      }
    })
    if (!filter) {
      filter = '';
    }
    filter = `role = "patient"`;

    try {
      await this.userService.fetch(start, limit, filter, sortBy);
    } catch (err: any) {
      console.error(err)
      runInAction(() => {
        this._error = err.message;
      });
    } finally {
      runInAction(() => {
        this._loading = false;
      })
    }
  }
}