import { injectable } from "inversify";
import { action, computed, makeObservable, observable } from "mobx";
import { DialogModel } from "../models/dialog";

export interface IDialogStore {
  readonly open: boolean;
  readonly data: DialogModel<any> | null;
  set(open: boolean, data: DialogModel<any> | null): void;
  clean(): void;
}

export const DialogStoreSymbol = Symbol.for('IDialogStore');

@injectable()
export class DialogStore implements IDialogStore{

  @observable
  private _data: DialogModel<any> | null;

  @observable
  private _open: boolean;

  public constructor() {
    this._data = null;
    this._open = false;
    makeObservable(this)
  }

  @computed
  public get open(): boolean {
    return this._open
  }

  @computed
  public get data(): DialogModel<any> | null {
    return this._data
  }

  @action
  public set(open: boolean, data: DialogModel<any> | null): void {
    this._open = open;
    this._data = data;
  }

  @action
  public clean(): void {
    this._open = false;
    this._data = null;
  }
}