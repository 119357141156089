import { memo } from "react";
import { Observer } from "mobx-react-lite";
import { useBloc } from "../ioc";
import { RegionAddBloc } from "./region-add.bloc";
import { SelectableInput } from "../components/select-input";
import { TextField } from "../components/text-field";
import { ArrayTextField } from "../components/array-text-field";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { PlaceAutocompleteComponent } from "../components/places-autocomplete-input";

export const RegionAddDialog = memo((props) => {
  const bloc = useBloc(RegionAddBloc, props);
  return (
    <Observer>
      {() => {
        const {
          formData,
          loading,
          error,
          loadingBtn,
          onClose,
          onSubmit,
          onCancel,
        } = bloc;

        if (error) {
          return (
            <Dialog open={true} onClose={onClose}>
              <div style={{ color: "red" }}>{error}</div>
            </Dialog>
          );
        }
        if (loading) {
          return (
            <Dialog open={true} onClose={onClose}>
              <div>Loading...</div>
            </Dialog>
          );
        }

        return (
          <Dialog
            open={true}
            onClose={onClose}
            maxWidth={"md"}
            fullWidth={true}
          >
            <DialogTitle> Add region </DialogTitle>
            <Box component="form" onSubmit={onSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                  <SelectableInput {...formData.country} />
                  <SelectableInput {...formData.status} />
                  <SelectableInput {...formData.pricelist} />
                  <TextField
                    id="description"
                    label="Description"
                    {...formData.description}
                  />
                  <ArrayTextField
                    id="zipCodes"
                    label="Zip Codes"
                    {...formData.zipCodes}
                  />
                  <PlaceAutocompleteComponent
                    {...formData.coordinates}
                  />
                  <TextField id="radius" label="Radius" {...formData.radius} />
                  <SelectableInput {...formData.responsibleParty} />
                </Stack>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  loading={loadingBtn}
                  type="submit"
                  fullWidth
                >
                  Submit
                </LoadingButton>
                <Button onClick={onCancel}>Cancel</Button>
              </DialogActions>
            </Box>
          </Dialog>
        );
      }}
    </Observer>
  );
});
