import { inject, injectable } from "inversify";
import { IPriceListApi, PriceListApiSymbol } from "../api";
import { PriceListInput, IFetchFilter, ISortBy } from "../models";
import { IPriceListStore, PriceListStoreSymbol } from "../stores";

export interface IPriceListService {
  fetch(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<void>;
  updatePriceList(uid: string, input: PriceListInput): Promise<void>;
  addPriceList(input: PriceListInput): Promise<void>;
}

export const PriceListServiceSymbol = Symbol.for("IPriceListService");

@injectable()
export class PriceListService implements IPriceListService {
  @inject(PriceListApiSymbol)
  private pricelistApi!: IPriceListApi;

  @inject(PriceListStoreSymbol)
  private pricelistStore!: IPriceListStore;

  public async fetch(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<void> {
    const [data, count] = await Promise.all([
      this.pricelistApi.list(start, limit, filter, sortBy),
      this.pricelistApi.count(filter),
    ]);
    this.pricelistStore.reset(data, count);
  }

  public async updatePriceList(
    uid: string,
    input: PriceListInput
  ): Promise<void> {
    const pricelist = await this.pricelistApi.putPriceList(uid, input);
    this.pricelistStore.refresh([pricelist]);
  }

  public async addPriceList(input: PriceListInput): Promise<void>{
    const pricelist = await this.pricelistApi.postPriceList(input)
    this.pricelistStore.refresh([pricelist]);
  }
}
