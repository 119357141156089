import React, { memo } from 'react'
import {LoadingButton, LoadingButtonProps} from '@mui/lab';

export const Button = memo<LoadingButtonProps>(props => {
  const {variant, fullWidth, children, ...otherProps} = props;
  return (
    <LoadingButton
      variant={variant || 'contained'}
      fullWidth={fullWidth || true}
      {...otherProps}
    >
      {children}
    </LoadingButton>
  )
})