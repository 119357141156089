import { validate, presence, email, length, type } from '@ts-awesome/validate';
import { readable } from '@ts-awesome/model-reader';

export class DoctorInput {

  @validate([presence(), length({ minimum: 1 })])
  @readable(String)
  public firstName!: string;

  @validate([presence(), length({ minimum: 1 })])
  @readable(String)
  public lastName!: string;

  @validate([presence(), length({ minimum: 1 })])
  @readable(String)
  public speciality!: string;

  @validate([presence()])
  @readable(Boolean)
  public approved!: boolean;
}