import { HttpTransport } from '@ts-awesome/request';
import { HttpMethod, ILogger, TokenProvider } from '@ts-awesome/request/dist/interfaces';

declare type BaseUrlResolver = () => string;

export class AdminHttpTransport extends HttpTransport {

  private baseUrlResolver?: BaseUrlResolver | undefined;

  constructor(
    logger?: ILogger,
    baseUrl?: string | BaseUrlResolver,
    authorization?: string | TokenProvider,
    protected unauthorizedEvent?: () => void
  ) {
    super(logger, typeof baseUrl === 'string' ? baseUrl : undefined, authorization);
    this.baseUrlResolver = typeof baseUrl === 'function' ? baseUrl : undefined;
  }

  protected resolveError(statusMessage: string, statusCode: number, body: any): void {
    if (statusCode === 401 && this.unauthorizedEvent) {
      this.unauthorizedEvent();
    }
    super.resolveError(statusMessage, statusCode, body);
  }

  protected resolveRelativeUrl(method: HttpMethod, uri: string): string {
    const baseUrl = this.baseUrlResolver ? this.baseUrlResolver() : this.baseUrl;
    if (!baseUrl) {
      throw new Error(`Unable to resolve relative URL ${method} ${uri}. Please override resolveRelativeUrl() method`);
    }

    return `${baseUrl}${uri.startsWith('/') ? '' : '/'}${uri}`;
  }
}
