export enum NotificationType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error'
}

export interface INotification {
  message: string;
  type: NotificationType;
}