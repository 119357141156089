import { injectable } from "inversify";
import { observable, action, makeObservable, computed, runInAction, reaction } from 'mobx';

export interface IAuthStore {
  readonly token: string | null;
  readonly isAuth: boolean;
  set(token: string): void;
  clean(): void;
}

export const AuthStoreSymbol = Symbol.for('IAuthStore')

@injectable()
export class AuthStore implements IAuthStore {

  @observable
  public token: string | null = null;

  public constructor() {
    runInAction(() => {
      this.token = localStorage.getItem('token');
    });

    makeObservable(this)
    reaction(() => this.token, _ => {
      console.log('Token reacted', _)
      if (_) {
        localStorage.setItem('token', _);
      } else {
        localStorage.removeItem('token');
      }
    })
  }

  @computed get isAuth() {
    return !!this.token
  }

  @action
  public set(token: string): void {
    if (this.token !== token) {
      this.token = token;
    }
  }

  @action
  public clean(): void {
    if (this.token) {
      this.token = null;
    }
  }
}