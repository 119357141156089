import { injectable } from "inversify";
import { observable, action, makeObservable } from 'mobx';
import { Attachment } from '../models';

export interface IAttachmentStore {
  add(ipt: Attachment): void;
  find(uid: string): Omit<Attachment, 'publicUrl'> | null;
  remove(uid: string): void;
}

export const AttachmentStoreSymbol = Symbol.for('IAttachmentStore')

@injectable()
export class AttachmentStore implements IAttachmentStore {

  @observable
  private _data: Record<string, Omit<Attachment, 'publicUrl'>>  = {}

  public constructor() {
    makeObservable(this)
  }

  @action
  public add(ipt: Attachment): void {
    const uid = ipt.uid;
    const {publicUrl, ...attachment} = ipt
    this._data[uid] = attachment
  }

  @action
  public find(uid: string): Omit<Attachment, 'publicUrl'> | null {
    return this._data[uid] ?? null
  }

  @action
  public remove(uid: string): void {
    delete this._data[uid]
  }
}