import { readable } from "@ts-awesome/model-reader";
import { Coordinates } from './coordinates'

enum AvailabilityStatus {
  Available = "available",
  ComingSoon = "coming-soon",
  Unavailable = "unavailable",
}

export class Region {
  @readable(String) public uid!: string;
  @readable(String) public countryUid!: string; ///
  @readable(Date) public createdAt!: Date;
  @readable(String) public createdBy!: string;
  @readable(Date) public updatedAt!: Date;
  @readable(String) public updatedBy!: string;
  @readable(Coordinates) public coordinates!: Coordinates;
  @readable(Number) public radius!: number;
  @readable(String) public description!: string;
  @readable(String) public status!: AvailabilityStatus;
  @readable(String) public pricelistUid!: string;
  @readable([String]) public zipCodes!: string[];
  @readable(String) public responsiblePartyUid!: string;
}
