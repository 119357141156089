import {
    validate,
    presence,
    length,
    alphaNumeric,
    inclusion,
    array,
    numericality,
    model
  } from "@ts-awesome/validate";
  import { readable } from "@ts-awesome/model-reader";
  import { AvailabilityStatuses } from "../country";
  import { Coordinates } from "../coordinates";
  
  export class RegionInput {
  
    @validate([presence()])
    @readable(String)
    public country!: string;

    @validate([presence(), alphaNumeric(false), inclusion(AvailabilityStatuses)])
    @readable(String)
    public status!: string;

    @validate([presence()])
    @readable(String)
    public pricelist!: string;

    @validate([presence(), length({ maximum: 128 })])
    @readable(String)
    public description!: string;

    @validate([presence(), array({ element: [presence(), length({minimum: 2, maximum: 10})] })])
    @readable([String])
    public zipCodes!: string[];

    @validate([presence(), model(Coordinates)])
    @readable(Coordinates)
    public coordinates!: Coordinates;

    @validate([
        presence(),
        numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
      ])
      @readable(Number)
      public radius!: number;

    @validate([presence()])
    @readable(String)
    public responsibleParty!: string;
  }