import { memo } from "react";
import { useBloc } from "../../../ioc";
import { CountryBloc } from "./country.bloc";
import { Grid, ICollapsibleRowProps } from "../../../components/grid";
import { Observer } from "mobx-react-lite";
import { Country } from "../../../models";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const collapsibleRowRenderer = ({
  row,
  collapsibleRowItemActions,
  collapsibleRowItemValue,
}: ICollapsibleRowProps<Country>) => {
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Details
      </Typography>
      <Table size="small" aria-label="countryDetails">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f4f4f4" }}>
            <TableCell key="field">Field</TableCell>
            <TableCell key="value">Value</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow key={`pricelist`}>
            <TableCell key="field">Pricelist</TableCell>
            <TableCell key="value">
              <Button
                sx={{ backgroundColor: "#f4f4f4" }}
                onClick={collapsibleRowItemActions?.(
                  "pricelistUid",
                  row.pricelistUid
                )}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
          <TableRow key={`emergencyPhone`}>
            <TableCell key="field">Emergency Phone</TableCell>
            <TableCell key="value">{row.emergencyPhone}</TableCell>
          </TableRow>
          <TableRow key={`doctorTemplate`}>
            <TableCell key="field">Doctor Template</TableCell>
            <TableCell key="value">
              <Button
                sx={{ backgroundColor: "#f4f4f4" }}
                onClick={collapsibleRowItemActions?.(
                  "doctorTemplateUid",
                  row.doctorTemplateUid
                )}
              >
                Download
              </Button>
            </TableCell>
          </TableRow>
          <TableRow key={`patientTemplate`}>
            <TableCell key="field">Patient Template</TableCell>
            <TableCell key="value">
              <Button
                sx={{ backgroundColor: "#f4f4f4" }}
                onClick={collapsibleRowItemActions?.(
                  "patientTemplateUid",
                  row.patientTemplateUid
                )}
              >
                Download
              </Button>
            </TableCell>
          </TableRow>
          <TableRow key={`childTemplate`}>
            <TableCell key="field">Child Template</TableCell>
            <TableCell key="value">
              <Button
                sx={{ backgroundColor: "#f4f4f4" }}
                onClick={collapsibleRowItemActions?.(
                  "childTemplateUid",
                  row.childTemplateUid
                )}
              >
                Download
              </Button>
            </TableCell>
          </TableRow>
          <TableRow key={`responsibleParty`}>
            <TableCell key="field">Responsible Party</TableCell>
            <TableCell key="value">
              {collapsibleRowItemValue?.(
                "responsiblePartyUid",
                row.responsiblePartyUid
              ) ?? ""}
            </TableCell>
          </TableRow>
          <TableRow key={`stripePublicKey`}>
            <TableCell key="field">Stripe Public Key</TableCell>
            <TableCell key="value">{row.stripePublicKey}</TableCell>
          </TableRow>
          <TableRow key={`stripeSecretKey`}>
            <TableCell key="field">Stripe Secret Key</TableCell>
            <TableCell key="value">{row.stripeSecretKey}</TableCell>
          </TableRow>
          <TableRow key={`stripeWebhookSecret`}>
            <TableCell key="field">Stripe Webhook Secret</TableCell>
            <TableCell key="value">{row.stripeWebhookSecret}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export const CountryPage = memo((props: any) => {
  const bloc = useBloc(CountryBloc, { ...props });
  return (
    <Observer>
      {() => {
        const { gridData } = bloc;
        let grid = { ...gridData };

        grid.collapsibleRowRenderer = collapsibleRowRenderer;

        grid.columns = [
          ...grid.columns,
          {
            field: "actions",
            headerName: "Actions",
            renderer: ({ row }) => {
              return (
                <>
                  <Button onClick={bloc.onCountryEdit(row.uid)}>Edit</Button>
                </>
              );
            },
          },
        ];

        return (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{ backgroundColor: "#f4f4f4", mb: "0.5em", mt: "0.5em" }}
                onClick={bloc.onAddNewCountry()}
              >
                Add a country
              </Button>
            </Box>
            <Grid {...grid} />
          </Box>
        );
      }}
    </Observer>
  );
});
