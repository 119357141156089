import { useRef, useState, useEffect } from "react";
import {
  useJsApiLoader,
  Autocomplete,
  GoogleMap,
  MarkerF,
} from "@react-google-maps/api";
import { TextField, TextFieldProps } from "@mui/material";
import Box from "@mui/material/Box";

const libraries = ["places"] as "places"[];

interface PlaceAutocompleteProps {
  value?: { latitude: number; longitude: number };
  error: string | null | undefined;
  onChange: (v: { latitude: number; longitude: number }) => void;
  placeholder?: string;
}

export type PlaceAutocompleteInput = PlaceAutocompleteProps &
  Omit<TextFieldProps, "error" | "onChangeText" | "onChange" | "onTextInput">;

export const PlaceAutocompleteComponent = (props: PlaceAutocompleteInput) => {

  const { value, error, onChange, style, placeholder, ...otherProps } = props;

  const autocompleteRef = useRef<google.maps.places.Autocomplete>();

  const [center, setCenter] = useState<{ lat: number; lng: number }>({ 
    lat: value?.latitude ?? 55.588194, 
    lng: value?.longitude ?? -46.412457 
  });
  const [text, setText] = useState<string>("");
  const [zoom, setZoom] = useState<number>(() => value ? 10 : 3);
  const [position, setPosition] = useState<{ lat: number; lng: number }>();

  useEffect(() => {
    if(value) setPosition(center);
  }, [center, value]);

  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
    libraries: libraries,
  });

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onMapLoad = (map) => {
    map.addListener("zoom_changed", () => {
      setZoom(map.zoom);
    });
  };
  const onMapUnmount = (map) => {
    google.maps.event.clearInstanceListeners(map);
  };

  const onPlaceChanged = () => {
    const place = autocompleteRef.current?.getPlace();
    if (place) {
      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng();
      if (lat && lng) {
        onChange?.({ latitude: lat, longitude: lng });
        setCenter({ lat, lng });
        setZoom(10);
      }
      if (place.formatted_address) setText(place.formatted_address);
    }
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          id="searchbox-example"
          mapContainerStyle={{ width: "100%", height: "400px" }}
          onLoad={onMapLoad}
          onUnmount={onMapUnmount}
          zoom={zoom}
          center={center}
        >
          <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                error={!!error}
                placeholder={placeholder ?? "Enter location"}
                helperText={error || ""}
                onChange={(e) => setText(e.target.value)}
                value={text ?? ""}
                style={{
                  backgroundColor: "white",
                  borderRadius: "3px",
                  marginTop: "10px",
                }}
                {...otherProps}
              />
              <div className={"pac-container"}></div>
              {position ? <MarkerF position={position} /> : <></>}
            </Box>
          </Autocomplete>
        </GoogleMap>
      )}
    </>
  );
};
