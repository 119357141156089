import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IBloc } from "../../ioc";
import { SignUpInput } from "../../models/input/singup.input";
import { AuthServiceSymbol, IAuthService, INavigationService, NavigationServiceSymbol } from "../../services";
import { AuthStoreSymbol, IAuthStore } from "../../stores";

import { ValidateAutomate } from "@ts-awesome/validate";

interface IFormField {
  value: string;
  error: string | null | undefined;
  onChange: (v: string | any) => void;
}

interface ISignUpFormViewData {
  email: IFormField;
  sysToken: IFormField;
  password: IFormField;
  confirmPassword: IFormField;
}

export interface ISignUpBloc extends IBloc<any> {
  formData: ISignUpFormViewData;
  saveEnabled: boolean;
  loading: boolean;
  error: string;
  onSignUp(e?: any): void;
}

@injectable()
export class SignUpBloc implements ISignUpBloc {

  @inject(AuthServiceSymbol) authService!: IAuthService;
  @inject(NavigationServiceSymbol) navigationService!: INavigationService;

  @inject(AuthStoreSymbol) authStore!: IAuthStore;

	@observable
  private _apiError = '';

  @observable
  private _loading = false;

  @observable
  private _saveEnabled: boolean = true;

  @observable private _formData: SignUpInput = {
    email: '',
    sysToken: '',
    password: '',
    confirmPassword: ''
  };

  @observable
  private _errors: Partial<Record<keyof SignUpInput, string | null | undefined>> = {
    email: null,
    password: null
  };

  private _validator: ValidateAutomate<SignUpInput> | null = null;

  constructor() {
    makeObservable(this);
  }

  mount(props: any): void {
    this._validator = new ValidateAutomate(SignUpInput);
    this._validator.update({...this._formData}).reset();
    runInAction(() => {
      this._saveEnabled = this._validator?.attempted ? this._validator?.valid : true
    });
  }

  @computed
  public get formData(): ISignUpFormViewData {
    return {
      email: {
        value: this._formData.email,
        error: this._errors.email,
        onChange: this.onChange('email')
      },
      sysToken: {
        value: this._formData.password,
        error: this._errors.password,
        onChange: this.onChange('sysToken')
      },
      password: {
        value: this._formData.password,
        error: this._errors.password,
        onChange: this.onChange('password')
      },
      confirmPassword: {
        value: this._formData.password,
        error: this._errors.password,
        onChange: this.onChange('confirmPassword')
      }
    }
  }

  @computed
  public get saveEnabled(): boolean {
    return this._saveEnabled;
  }

  @computed
  public get loading(): boolean {
    return this._loading;
  }

  @computed
  public get error(): string {
    return this._apiError;
  }

  public onSignUp = async (e: any) => {
    e.preventDefault();
    if (this._validator?.validate()) {
      console.warn('Not valid model');
    }

    runInAction(() => {
      this._errors = {...this._validator?.errors};
      this._saveEnabled = this._validator?.attempted ? this._validator?.valid : true;
    });

    if (this._formData.password !== this._formData.confirmPassword) {
      runInAction(() => {
        this._errors.confirmPassword = 'Confirm password is incorrect.';
      });
    }

    if (Object.keys(this._errors).length) {
      console.log('ERRORS', this._errors);
      return;
    }

    runInAction(() => this._loading = true);
    try {
      let data = this._validator?.read()!;
      await this.authService.signUp(data.email, data.password, data.sysToken);
      this.navigationService.navigate('/');
    } catch (err: any) {
      runInAction(() => {
        this._apiError = err.message;
      });
      console.log(err);
    } finally {
      runInAction(() => this._loading = false);
    }

  }

  private onChange = (prop: keyof SignUpInput) => (e: any) => {
    const value = e.target.value;
    this._validator?.update({
      [prop]: value,
    });

    runInAction(() => {
      this._formData = {...this._formData, [prop]: value};
      this._errors = {...this._errors, [prop]: this._validator?.get(prop as never) ?? null};
      this._saveEnabled = this._validator?.attempted ? this._validator?.valid : true;
    });
  } 

}