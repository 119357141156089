import { inject, injectable } from "inversify";
import { AuthApiSymbol, IAuthApi, IUserApi, UserApiSymbol } from "../api";
import { AuthStoreSymbol, IAuthStore, IMeUserStore, MeUserStoreSymbol } from "../stores";

export interface IAuthService {
  signUp(email: string, password: string, token: string): Promise<void>;
  singIn(email: string, password: string): Promise<void>;
  singOut(): void;
}

export const AuthServiceSymbol = Symbol.for('IAuthService');

@injectable()
export class AuthService implements IAuthService {

  @inject(AuthApiSymbol)
  private authApi!: IAuthApi;

  @inject(UserApiSymbol)
  private userApi!: IUserApi;

  @inject(AuthStoreSymbol)
  private authStore!: IAuthStore;

  @inject(MeUserStoreSymbol)
  private meUserStore!: IMeUserStore;

  public async signUp(email: string, password: string, sysToken: string): Promise<void> {
    const {token, auth} = await this.authApi.singUpAdmin(email, password, sysToken);
    if (auth) {
      this.authStore.set(token);
      const user = await this.userApi.getMe();
      this.meUserStore.set(user);
    }
  }

  public async singIn(email: string, password: string): Promise<void> {
    const {token, auth} = await this.authApi.singIn(email, password);
    if (auth) {
      this.authStore.set(token);
      const user = await this.userApi.getMe();
      this.meUserStore.set(user);
    }
  }

  public singOut(): void {
    this.authStore.clean();
  }
}