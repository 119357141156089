import {inject, injectable} from 'inversify';
import type {IHttpTransport} from '@ts-awesome/request';
import {HttpTransportSymbol} from '@ts-awesome/request';
import {Auth} from '../models/auth';

export interface IAuthApi {
  singIn(email: string, password: string): Promise<Auth>;

  singUpAdmin(email: string, password: string, sysToken: string): Promise<Auth>;
}

export const AuthApiSymbol = Symbol.for('IAuthApi');

@injectable()
export class AuthApi implements IAuthApi {

  @inject(HttpTransportSymbol) private http!: IHttpTransport;

  public singIn(email: string, password: string): Promise<Auth> {
    if (!email) {
      throw new Error('Email is missing');
    }
    if (!password) {
      throw new Error('Password is missing');
    }

    return this.http.post<Auth>('api/v1/auth/signin',
        {body: {email, password}});
  }

  public singUpAdmin(email: string, password: string, sysToken: string): Promise<Auth> {
    if (!email) {
      throw new Error('Email is missing');
    }
    if (!password) {
      throw new Error('Password is missing');
    }
    if (!sysToken) {
      throw new Error('SysToken is missing');
    }
    return this.http.post<Auth>('/api/v1/auth/signup/admin', {
      body: {email, password},
      headers: {'sys-token': sysToken}
    });
  }
}