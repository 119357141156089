import React, { memo } from 'react';
import { Observer } from 'mobx-react-lite';
import {useBloc} from '../../ioc';
import { SignUpBloc } from './signup.bloc';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

export const SignUpPage = memo(props => {
  const bloc = useBloc(SignUpBloc, props)
  return (
    <Observer>
      {() => (
        <Container style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}>
          <Card sx={{ minWidth: 600 }}>
          <Box component="form" onSubmit={bloc.onSignUp}>
            <CardHeader title="Sign Up">
              Sign Up
            </CardHeader>
            <CardContent>
              <Stack spacing={2}>
                <TextField
                  id="email"
                  label="Email"
                  error={!!bloc.formData.email.error}
                  helperText={bloc.formData.email.error || ''}
                  onChange={bloc.formData.email.onChange}
                />
                <TextField
                  id="sys-token"
                  label="Sys token"
                  error={!!bloc.formData.sysToken.error}
                  helperText={bloc.formData.sysToken.error || ''}
                  onChange={bloc.formData.sysToken.onChange}
                />           
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  error={!!bloc.formData.password.error}
                  helperText={bloc.formData.password.error || ''}
                  onChange={bloc.formData.password.onChange}
                />
                <TextField
                  id="confirm-password"
                  label="Confirm Password"
                  type="password"
                  error={!!bloc.formData.confirmPassword.error}
                  helperText={bloc.formData.confirmPassword.error || ''}
                  onChange={bloc.formData.confirmPassword.onChange}
                />
              </Stack>
            </CardContent>
            <CardActions>
              <LoadingButton 
                variant="contained"
                loading={bloc.loading}
                type="submit"
                fullWidth
              >
                Sing Up
              </LoadingButton>
            </CardActions>
          </Box>
          </Card>
        </Container>
      )}
    </Observer>
  )
})