import { useEffect, useRef, ReactElement, memo } from "react";
import { Observer } from "mobx-react-lite";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useBloc } from "../../../ioc";
import { WaitingConsultationMapBloc, IMarkerItem, IDoctorLocationItem } from "./waiting-consultations-map.bloc";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ReactDOMServer from "react-dom/server";
import { DoctorLocation } from "../../../models";

export const WaitingConsultationsMapPage = memo((props: any) => {
  const bloc = useBloc(WaitingConsultationMapBloc, { ...props });
  const zoom = 10;
  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;
  // console.log('mapCenter: ', bloc.mapCenter)
  // setTimeout(() => console.log('doctor locations: ', JSON.stringify(bloc.activeDoctorLocations)), 500)
  return (
    <Observer>
      {() => {
        const { mapCenter, markerData, activeDoctorLocations, isLoaded } = bloc;
        if (!isLoaded) return <></>
        return (
           <Wrapper apiKey={apiKey} render={render}>
              <MapComponent
                center={mapCenter}
                zoom={zoom}
                markerData={markerData}
                activeDoctorLocations={activeDoctorLocations}
              />
            </Wrapper>
        );
      }}
    </Observer>
  );
});

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <CircularProgress />;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <></>;
};

interface IMapComponentProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  markerData: IMarkerItem[];
  activeDoctorLocations: IDoctorLocationItem[]
}

const MapComponent = ({
  center,
  zoom,
  markerData,
  activeDoctorLocations
}: IMapComponentProps) => {
  const ref = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });

    let markers: google.maps.Marker[] = [];
    let bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    const infowindow = new google.maps.InfoWindow({
      content: "",
    });

    markerData.forEach((item) => {
      let marker = new google.maps.Marker({
        position: item.location,
        map,
      });
      markers.push(marker);
      bounds.extend(item.location);

      marker.addListener("click", () => {
        const content = ReactDOMServer.renderToString(MarkerTooltip(item));
        infowindow.setContent(content);
        infowindow.open({
          anchor: marker,
          map,
        });
      });
    });

    activeDoctorLocations.forEach((loc) => {
      new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 0.2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map,
        center: loc.location,
        radius: loc.radius,
      });
      bounds.extend(loc.location);
    })

    map.fitBounds(bounds);
    new MarkerClusterer({ map, markers });
  });

  return <div ref={ref} style={{ width: "100%", height: "100vh" }} />;
};

const MarkerTooltip = (info: IMarkerItem) => {
  return (
    <div style={{ color: "#9e9478" }}>
      <Box>Patient: {info.patient}</Box>
      <Box>Medical care: {info.medicalCare}</Box>
      <Box>Created at: {info.createdAt.toString()}</Box>
    </div>
  );
};
