import { readable } from "@ts-awesome/model-reader";

export enum AvailabilityStatus {
  Available = "available",
  ComingSoon = "coming-soon",
  Unavailable = "unavailable",
}
export const AvailabilityStatuses = [
  AvailabilityStatus.Available,
  AvailabilityStatus.ComingSoon,
  AvailabilityStatus.Unavailable,
];

export class Country {
  @readable(String) public uid!: string;
  @readable(Date) public createdAt!: Date;
  @readable(String) public createdBy!: string;
  @readable(Date) public updatedAt!: Date;
  @readable(String) public updatedBy!: string;
  @readable(String) public country!: string;
  @readable(String) public pricelistUid!: string;
  @readable(String) public status!: AvailabilityStatus;
  @readable(String) public emergencyPhone!: string;
  @readable(String) public doctorTemplateUid!: string;
  @readable(String) public patientTemplateUid!: string;
  @readable(String) public childTemplateUid!: string;
  @readable(String) public responsiblePartyUid!: string;
  @readable(String) public stripePublicKey!: string;
  @readable(String) public stripeSecretKey!: string;
  @readable(String) public stripeWebhookSecret!: string;
}
