
import React, { memo } from 'react'
import { Route, BrowserRouter, Routes, Outlet, Navigate } from 'react-router-dom';
import { MainLayout } from './layouts/main-layout';
import { Observer } from 'mobx-react-lite'
import './App.css';
import { useBloc } from './ioc';
import { AppBloc, IAppBloc } from './app.bloc';

import { ProtectedRoute } from './components/protected.route';

import { SignInPage } from './pages/auth/signin.page';
import { SignUpPage } from './pages/auth/signup.page';
import { AppHeader } from './components/app-header';

import { LocalizationHeader } from './pages/main/localizations/localization-header';
import { PriceListsPage } from './pages/main/localizations/pricelists.page'
import { CountryPage } from './pages/main/localizations/country.page'
import { RegionPage } from './pages/main/localizations/region.page';
import { UsersHeader } from './pages/main/users/users-header';
import { PatientsPage } from './pages/main/users/patients.page';
import { DoctorsPage } from './pages/main/users/doctors.page';
import { ConsultatoionsHeader } from './pages/main/consultations/consultations-header';
import { ActiveConsultationsPage } from './pages/main/consultations/active-consultations.page';
import { FinishedConsultationsPage } from './pages/main/consultations/finished-consultations.page';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { DoctorUpdateDialog } from './dialogs/doctor-update.dialog';
import { PriceListUpdateDialog } from './dialogs/pricelist-update.dialog';
import { PriceListAddDialog } from './dialogs/pricelist-add.dialog'; 
import { CountryAddDialog } from './dialogs/country-add.dialog'; 
import { CountryUpdateDialog } from './dialogs/country-update.dialog';
import { RegionAddDialog } from './dialogs/region-add.dialog'; 
import { RegionUpdateDialog } from './dialogs/region-update.dialog'; 
import { WaitingConsultationsMapPage } from './pages/main/consultations/waiting-consultations-map.page';


export const App = memo(props => {
  return (
    <BrowserRouter>
      <InitialPage />
    </BrowserRouter>
  )
})

export const InitialPage = memo(props => {
  const bloc = useBloc<IAppBloc>(AppBloc, props);
  return (
    <Observer>
      {() => bloc.loading ? (<div>Loading...</div>) : (
        <Routes>
          <Route path='' element={<MainLayout />}>
            <Route path="/singin" element={<SignInPage />}/>
            <Route path="/singup" element={<SignUpPage />}/>
            {/* <Route path="/" render={(props: any) => (
              bloc.authenticated
                ? <div>Home page</div>
                : <Navigate  to='/signup' replace />
            )} /> */}

            <Route
              path="/"
              element={
                <ProtectedRoute authenticated={bloc.authenticated}>
                  {bloc.doctorDialogOpen ? <DoctorUpdateDialog {...bloc.dialogProps} /> : <></>}
                  {bloc.pricelistUpdateDialogOpen ? <PriceListUpdateDialog {...bloc.dialogProps} /> : <></>}
                  {bloc.pricelistAddDialogOpen ? <PriceListAddDialog {...bloc.dialogProps} /> : <></>}
                  {bloc.countryAddDialogOpen ? <CountryAddDialog {...bloc.dialogProps} /> : <></>}
                  {bloc.countryUpdateDialogOpen ? <CountryUpdateDialog {...bloc.dialogProps} /> : <></>}
                  {bloc.regionAddDialogOpen ? <RegionAddDialog {...bloc.dialogProps} /> : <></>}
                  {bloc.regionUpdateDialogOpen ? <RegionUpdateDialog {...bloc.dialogProps} /> : <></>}
                  <Box sx={{ display: 'flex' }}>
                    <AppHeader onSignOutClick={bloc.onSignOut}/>
                    <Box component="main" sx={{ p: 3, width: '100vw' }}>
                      <Toolbar />
                      <Outlet />
                    </Box>
                  </Box>
                </ProtectedRoute>
              }
            >
              <Route path="/" element={<Navigate to="/users" />}></Route>

              <Route path="/users" element={<UsersHeader />}>
                <Route index element={<PatientsPage />} />
                <Route path="patients" element={<PatientsPage />}/>
                <Route path="doctors" element={<DoctorsPage />}/>
              </Route>

              <Route path="/consultations" element={<ConsultatoionsHeader />}>
                <Route index element={<ActiveConsultationsPage />} />
                <Route path="active" element={<ActiveConsultationsPage />}/>
                <Route path="finished" element={<FinishedConsultationsPage />}/>
                <Route path="waiting-map" element={<WaitingConsultationsMapPage/> }/>
              </Route>

              <Route path="/localization" element={<LocalizationHeader />}>
                <Route index element={<PriceListsPage />} />
                <Route path="pricelists" element={<PriceListsPage />}/>
                <Route path="countries" element={<CountryPage />}/>
                <Route path="regions" element={<RegionPage />} />
              </Route>

            </Route>
          </Route>
        </Routes>
      )}
    </Observer>
  );
})
