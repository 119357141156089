import React, { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';
// import Drawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from '@mui/material/ListItemText';
// import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export type IAppHeaderProps = {
  onSignOutClick: (e: any) => void;
  // selectedLink: 
}

const navItems = [{
  name: 'Users',
  link: '/users'
}, {
  name: 'Consultations',
  link: '/consultations'
}, {
  name: 'Localization',
  link: '/localization'
}]

export const AppHeader = memo((props: IAppHeaderProps) => {
  return (
    <AppBar component="nav">
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, display: { sm: 'block' } }}
        >
          Invia Admin 
        </Typography>

        <Box sx={{ display: { sm: 'block' } }}>
          {navItems.map((item) => (
            <Button key={item.name} sx={{ color: '#fff' }} component={RouterLink} to={item.link}>
              {item.name}
            </Button>
          ))}
        </Box>
        <Box sx={{ display: { sm: 'block' } }}>
          <Button sx={{ color: '#fff' }} onClick={props.onSignOutClick}>
            Sign Out
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
})