import React, { memo } from "react";
import { Observer } from "mobx-react-lite";
import { useBloc } from "../ioc";
import { PriceListUpdateBloc } from "./pricelist-update.bloc";
import { TextField } from '../components/text-field'

import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from '@mui/material/InputAdornment';
import Button from "@mui/material/Button";

const boxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "4em",
};

export const PriceListUpdateDialog = memo((props) => {
  const bloc = useBloc(PriceListUpdateBloc, props);
  return (
    <Observer>
      {() => {
        const { formData, loading, onClose, onSubmit, onCancel, availability } =
          bloc;
        return (
          <Dialog open={true} onClose={onClose}>
            <DialogTitle> Prices </DialogTitle>
            <Box component="form" onSubmit={onSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                <TextField
                    id="description"
                    label="Description"
                    error={formData.description.error}
                    onChange={formData.description.onChange}
                    value={formData.description.value}
                  /> 
                  <TextField
                    id="currency"
                    label="Currency"
                    {...formData.currency}
                  />                                   
                  <Box sx={boxStyle}>
                    <TextField
                      id="pediatricianPrice"
                      label="Pediatrician Price (minor units)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                      }}
                      {...formData.pediatricianPrice}
                      disabled={!availability.pediatrician.value}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id="pediatrician-switch"
                          onChange={availability.pediatrician.onChange}
                          checked={availability.pediatrician.value}
                        />
                      }
                      label={availability.pediatrician.label}
                    />
                  </Box>
                  <Box sx={boxStyle}>
                    <TextField
                      id="familyInternistPrice"
                      label="FamilyInternist Price (minor units)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                      }}                      
                      {...formData.familyInternistPrice}
                      disabled={!availability.familyInternist.value}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id="familyInternist-switch"
                          onChange={availability.familyInternist.onChange}
                          checked={availability.familyInternist.value}
                        />
                      }
                      label={availability.familyInternist.label}
                    />
                  </Box>
                  <Box sx={boxStyle}>
                    <TextField
                      id="entPrice"
                      label="Ent Price (minor units)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                      }}                      
                      {...formData.entPrice}
                      />
                    <FormControlLabel
                      control={
                        <Switch
                          id="ent-switch"
                          onChange={availability.ent.onChange}
                          checked={availability.ent.value}
                        />
                      }
                      label={availability.ent.label}
                    />
                  </Box>
                  <Box sx={boxStyle}>
                    <TextField
                      id="woundCarePrice"
                      label="WoundCare Price (minor units)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                      }}                      
                      {...formData.woundCarePrice}                        
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id="woundCare-switch"
                          onChange={availability.woundCare.onChange}
                          checked={availability.woundCare.value}
                        />
                      }
                      label={availability.woundCare.label}
                    />
                  </Box>
                  <Box sx={boxStyle}>
                    <TextField
                      id="surgeonPrice"
                      label="Surgeon Price (minor units)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                      }}                      
                      {...formData.surgeonPrice}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id="surgeon-switch"
                          onChange={availability.surgeon.onChange}
                          checked={availability.surgeon.value}
                        />
                      }
                      label={availability.surgeon.label}
                    />
                  </Box>
                  <Box sx={boxStyle}>
                    <TextField
                      id="painManagementPrice"
                      label="PainManagement Price (minor units)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                      }}                      
                      {...formData.painManagementPrice}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id="painManagement-switch"
                          onChange={availability.painManagement.onChange}
                          checked={availability.painManagement.value}
                        />
                      }
                      label={availability.painManagement.label}
                    />
                  </Box>
                  <Box sx={boxStyle}>
                    <TextField
                      id="obGynPrice"
                      label="Ob Gyn Price (minor units)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                      }}                      
                      {...formData.obGynPrice}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id="obGyn-switch"
                          onChange={availability.obGyn.onChange}
                          checked={availability.obGyn.value}
                        />
                      }
                      label={availability.obGyn.label}
                    />
                  </Box>
                  <Box sx={boxStyle}>
                    <TextField
                      id="psychologyPrice"
                      label="Psychology Price (minor units)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                      }}                      
                      {...formData.psychologyPrice}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id="psychology-switch"
                          onChange={availability.psychology.onChange}
                          checked={availability.psychology.value}
                        />
                      }
                      label={availability.psychology.label}
                    />
                  </Box>
                  <TextField
                    id="cancellationFee"
                    label="Cancellation Fee (minor units)"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                    }}                    
                    {...formData.cancellationFee}
                  />
                  <TextField
                    id="serviceFee"
                    label="Service Fee (minor units)"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{formData.currency.value}</InputAdornment>,
                    }}                    
                    {...formData.serviceFee}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  type="submit"
                  fullWidth
                >
                  Submit
                </LoadingButton>
                <Button onClick={onCancel}>Cancel</Button>
              </DialogActions>
            </Box>
          </Dialog>
        );
      }}
    </Observer>
  );
});
