
type MergeIdentityType = string | number;

export function mergeBy<T>(
  mapper: (x: T) => MergeIdentityType,
  ...collections: Array<ReadonlyArray<T>>
): Array<T> {
  const map = new Map<MergeIdentityType, T>();
  collections.forEach(collection => {
    collection.forEach(item => {
      map.set(mapper(item), item);
    });
  });

  return [...map.values()];
}

export function merge<T extends {id: MergeIdentityType}>(
  ...collections: Array<ReadonlyArray<T>>
): ReadonlyArray<T> {
  return mergeBy<T>(({id}) => id, ...collections);
}

export function distinct<T>(collection: Array<T>) {
  const set = new Set<T>();
  collection.forEach(item => set.add(item));
  return [...set.values()];
}
