import { inject, injectable } from "inversify";
import { Consultation, IFetchFilter, ISortBy } from "../models";
import { HttpTransportSymbol, IHttpTransport } from '@ts-awesome/request';

export interface IConsultationApi {
  list(start?: number, limit?: number, filter?: IFetchFilter, sortBy?: ISortBy): Promise<Consultation[]>;
  count(filter?: IFetchFilter): Promise<number>;
}

export const ConsultationApiSymbol = Symbol.for('IConsultationApi');

@injectable()
export class ConsultationApi implements IConsultationApi {

  @inject(HttpTransportSymbol)
  private readonly http!: IHttpTransport;

  public list(start?: number, limit?: number, filter?: IFetchFilter, sortBy?: ISortBy): Promise<Consultation[]> {
    const qs: Record<string, string | number> = {};
    if (start) {
      qs.offset = start;
    }
    if (limit) {
      qs.limit = limit;
    }
    if (filter) {
      console.log('filter expression', filter);
      qs.q = filter;
    }
    if (sortBy) {
      qs.order = Object.keys(sortBy).map(col => `${col}${sortBy[col] === 'desc' ? '-' : '+'}`).join(',');
    }
    return this.http.get('/api/v1/consultation', {qs});
  }

  public count(filter?: IFetchFilter): Promise<number> {
    const qs: Record<string, string | number | boolean> = {count: true};
    if (filter) {
      qs.q = filter;
    }
    return this.http.get<number>('/api/v1/consultation', {qs});
  }
}
