import {inject, injectable} from "inversify";
import {IUserApi, UserApiSymbol} from "../api";
import {DoctorInput, IFetchFilter, ISortBy} from "../models";
import {IMeUserStore, IUserStore, MeUserStoreSymbol, UserStoreSymbol} from "../stores";

export interface IUserService {
    fetch(start?: number, limit?: number, filter?: IFetchFilter, sortBy?: ISortBy): Promise<void>;

    loadMe(): Promise<void>;

    loadDetails(uid: string): Promise<void>;

    updateDoctor(uid: string, input: Partial<DoctorInput>): Promise<void>;
    approveDoctor(uid: string, input: DoctorInput['approved']): Promise<void>
}

export const UserServiceSymbol = Symbol.for('IUserService');

@injectable()
export class UserService implements IUserService {

    @inject(UserApiSymbol)
    private userApi!: IUserApi;

    @inject(UserStoreSymbol)
    private userStore!: IUserStore;

    @inject(MeUserStoreSymbol)
    private meStore!: IMeUserStore;

    public async fetch(start?: number, limit?: number, filter?: IFetchFilter, sortBy?: ISortBy): Promise<void> {

        const [data, count] = await Promise.all([
            this.userApi.list(start, limit, filter, sortBy),
            this.userApi.count(filter)
        ]);
        this.userStore.reset(data, count);
    }

    public async loadMe(): Promise<void> {
        const user = await this.userApi.getMe();
        this.meStore.set(user);
    }

    public async loadDetails(uid: string): Promise<void> {
        const user = await this.userApi.get(uid);
        this.userStore.refresh([user]);
    }

    public async updateDoctor(uid: string, input: Partial<DoctorInput>): Promise<void> {
        const user = await this.userApi.putDoctor(uid, input);
        this.userStore.refresh([user]);
    }

    public async approveDoctor(uid: string, input: DoctorInput['approved']): Promise<void> {
        const user = await this.userApi.patchDoctor(uid, input);
        this.userStore.refresh([user]);
    }
}