import { inject, injectable } from "inversify";
import { Attachment } from "../models";
import { HttpTransportSymbol, IHttpTransport } from "@ts-awesome/request";

export interface IAttachmentApi {
  getAttachment(uid: string): Promise<Attachment>;
}

export const AttachmentApiSymbol = Symbol.for("IAttachmentApi");

@injectable()
export class AttachmentApi implements IAttachmentApi {
  @inject(HttpTransportSymbol)
  private readonly http!: IHttpTransport;

  public getAttachment(uid: string): Promise<Attachment> {
    return this.http.get(`/api/v1/attachment/${uid}`, { Model: Attachment });
  }
}
