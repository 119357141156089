import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction, } from "mobx";
import { IBloc } from "../../ioc";
import { AuthServiceSymbol, IAuthService, INavigationService, NavigationServiceSymbol } from "../../services";
import { ValidateAutomate } from "@ts-awesome/validate";
import { IFormField, SignInInput } from "../../models";
import { AuthStoreSymbol, IAuthStore } from "../../stores";

interface ISignInFormViewData {
  email: IFormField;
  password: IFormField;
}

export interface ISignInBloc extends IBloc<any> {
  formData: ISignInFormViewData;
  saveEnabled: boolean;
  loading: boolean;
  error: string;
  onSignIn(e?: any): void;
}

@injectable()
export class SignInBloc implements ISignInBloc {

  @inject(AuthServiceSymbol) authService!: IAuthService;
  @inject(NavigationServiceSymbol) navigationService!: INavigationService;

  @inject(AuthStoreSymbol) authStore!: IAuthStore;

	@observable
  private _apiError = '';

  @observable
  private _loading = false;

  @observable
  private _saveEnabled: boolean = true;

  @observable private _formData: SignInInput = {
    email: '',
    password: ''
  };

  @observable
  private _errors: Partial<Record<keyof SignInInput, string | null | undefined>> = {
    email: null,
    password: null
  };

  private _validator: ValidateAutomate<SignInInput> | null = null;

  constructor() {
    makeObservable(this);
  }

  mount(props: any): void {
    this._validator = new ValidateAutomate(SignInInput);
    this._validator.update({...this._formData}).reset();
    runInAction(() => {
      this._saveEnabled = this._validator?.attempted ? this._validator?.valid : true
    });
  }

  @computed
  public get formData(): ISignInFormViewData {
    return {
      email: {
        value: this._formData.email,
        error: this._errors.email,
        onChange: this.onChange('email')
      },
      password: {
        value: this._formData.password,
        error: this._errors.password,
        onChange: this.onChange('password')
      }
    }
  }

  @computed
  public get saveEnabled(): boolean {
    return this._saveEnabled;
  }

  @computed
  public get loading(): boolean {
    return this._loading;
  }

  @computed
  public get error(): string {
    return this._apiError;
  }

  public onSignIn = async (e: any) => {
    e.preventDefault();
    if (this._validator?.validate()) {
      console.warn('Not valid model');
    }

    runInAction(() => {
      this._errors = {
        ...this._validator?.errors
      };
      this._saveEnabled = this._validator?.attempted ? this._validator?.valid : true;
    });

    if (Object.keys(this._errors).length) {
      console.log('ERRORS', this._errors);
      return;
    }

    runInAction(() => this._loading = true);
    try {
      let data = this._validator?.read()!;
      await this.authService.singIn(data.email, data.password);
      if (this.authStore.isAuth) {
        this.navigationService.navigate('/');
      } else {
        runInAction(() => {
          this._errors.email = 'Invalid creds.';
          this._errors.password = 'Invalid creds.';
        });
      }

    } catch (err: any) {
      runInAction(() => {
        this._apiError = err.message;
      });
      console.log(err);
    } finally {
      runInAction(() => this._loading = false);
    }

  }

  private onChange = <T extends keyof SignInInput>(prop: T) => (value: string) => {
    this._validator?.update({
      [prop]: value,
    });

    runInAction(() => {
      this._formData = {...this._formData, [prop]: value};
      this._errors = {...this._errors, [prop]: this._validator?.get(prop as never) ?? null};
      this._saveEnabled = this._validator?.attempted ? this._validator?.valid : true;
    });
  } 

}