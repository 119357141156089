
export const CurrencyCents = {
    'USD': 100,
    'AED': 100,
    'AFN': 1,
    'ALL': 100,
    'AMD': 100,
    'ANG': 100,
    'AOA': 1,
    'ARS': 1,
    'AUD': 100,
    'AWG': 100,
    'AZN': 100,
    'BAM': 100,
    'BBD': 100,
    'BDT': 100,
    'BGN': 100,
    'BIF': 100,
    'BMD': 100,
    'BND': 100,
    'BOB': 1,
    'BRL': 1,
    'BSD': 100,
    'BWP': 100,
    'BZD': 100,
    'CAD': 100,
    'CDF': 100,
    'CHF': 100,
    'CLP': 1,
    'CNY': 100,
    'COP': 1,
    'CRC': 1,
    'CVE': 1,
    'CZK': 1,
    'DJF': 1,
    'DKK': 100,
    'DOP': 100,
    'DZD': 100,
    'EGP': 100,
    'ETB': 100,
    'EUR': 100,
    'FJD': 100,
    'FKP': 1,
    'GBP': 100,
    'GEL': 100,
    'GIP': 100,
    'GMD': 100,
    'GNF': 1,
    'GTQ': 1,
    'GYD': 100,
    'HKD': 100,
    'HNL': 1,
    'HRK': 100,
    'HTG': 100,
    'HUF': 1,
    'IDR': 100,
    'ILS': 100,
    'INR': 1,
    'ISK': 100,
    'JMD': 100,
    'JPY': 100,
    'KES': 100,
    'KGS': 100,
    'KHR': 100,
    'KMF': 100,
    'KRW': 100,
    'KYD': 100,
    'KZT': 100,
    'LAK': 1,
    'LBP': 100,
    'LKR': 100,
    'LRD': 100,
    'LSL': 100,
    'MAD': 100,
    'MDL': 100,
    'MGA': 100,
    'MKD': 100,
    'MMK': 100,
    'MNT': 100,
    'MOP': 100,
    'MRO': 100,
    'MUR': 1,
    'MVR': 100,
    'MWK': 100,
    'MXN': 100,
    'MYR': 100,
    'MZN': 100,
    'NAD': 100,
    'NGN': 100,
    'NIO': 1,
    'NOK': 100,
    'NPR': 100,
    'NZD': 100,
    'PAB': 1,
    'PEN': 1,
    'PGK': 100,
    'PHP': 100,
    'PKR': 100,
    'PLN': 100,
    'PYG': 1,
    'QAR': 100,
    'RON': 100,
    'RSD': 100,
    'RUB': 100,
    'RWF': 100,
    'SAR': 100,
    'SBD': 100,
    'SCR': 100,
    'SEK': 100,
    'SGD': 100,
    'SHP': 1,
    'SLL': 100,
    'SOS': 100,
    'SRD': 1,
    'STD': 1,
    'SZL': 100,
    'THB': 100,
    'TJS': 100,
    'TOP': 100,
    'TRY': 100,
    'TTD': 100,
    'TWD': 100,
    'TZS': 100,
    'UAH': 100,
    'UGX': 100,
    'UYU': 1,
    'UZS': 100,
    'VND': 100,
    'VUV': 100,
    'WST': 100,
    'XAF': 100,
    'XCD': 100,
    'XOF': 1,
    'XPF': 1,
    'YER': 100,
    'ZAR': 100,
    'ZMW': 100,
  };
  
  // noinspection JSUnusedGlobalSymbols
  export const CurrentISOList = Object.keys(CurrencyCents);
  export type CurrencyISO = keyof typeof CurrencyCents;
  
  