import { injectable } from "inversify";
import { observable, action, makeObservable, computed } from 'mobx';
import { Region } from '../models';
import { mergeBy } from "../utils";

export interface IRegionStore {
  readonly data: Region[];
  readonly count: number;
  
  refresh(data: readonly Region[]): void;
  reset(data: readonly Region[], count: number): void;
  find(uid: string): Region | null;
  remove(uid: string): void;
}

export const RegionStoreSymbol = Symbol.for('IRegionStore')

@injectable()
export class RegionStore implements IRegionStore {

  @observable
  private _data: Region[];

  @observable
  private _count: number;

  public constructor() {
    this._data = [];
    this._count = 0;
    makeObservable(this)
  }

  @computed get data(): Region[] {
    return this._data;
  }

  @computed get count(): number {
    return this._count;
  }

  @action
  public refresh(data: readonly Region[]): void {
    const oldDataCount = this._data.length;
    this._data = mergeBy(({uid}) => uid, this.data, data);
    if (this._data.length > oldDataCount) {
      // this._count++;
      this._count = this._data.length // ??? //
    }
  }

  @action
  public reset(data: readonly Region[], count: number): void {
    this._data = [...data];
    this._count = count;
  }

  @action
  public find(uid: string): Region | null {
    return this._data.find(_ => _.uid === uid) ?? null;
  }

  @action
  public remove(uid: string): void {
    this._data = this._data.filter(_ => _.uid !== uid);
    this._count--;
  }
}