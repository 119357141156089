import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IBloc } from "../ioc";
import { ObservableValidateAutomate } from "../utils/observable-validation-automate";
import {
  ICountryService,
  CountryServiceSymbol,
  DialogServiceSymbol,
  IDialogService,
  UserServiceSymbol,
  IUserService,
  PriceListServiceSymbol,
  IPriceListService,
} from "../services";
import { CountryInput, PriceList, User } from "../models";
import { IUploader } from "../components/file-uploader";
import { ISelect } from "../components/select-input";
import {
  UserStoreSymbol,
  IUserStore,
  PriceListStoreSymbol,
  IPriceListStore,
} from "../stores";
import { AvailabilityStatus } from "../models"

interface IFormTextField {
  value?: string;
  error: string | null | undefined;
  onChange: (v: string | any) => void;
}

interface ICountryFormViewData {
  country: IFormTextField;
  status: ISelect;
  pricelist: ISelect;
  emergencyPhone: IFormTextField;
  doctorTemplate: IUploader;
  patientTemplate: IUploader;
  childTemplate: IUploader;
  responsibleParty: ISelect;
  stripePublicKey: IFormTextField;
  stripeSecretKey: IFormTextField;
  stripeWebhookSecret: IFormTextField;
}

export interface ICountryAddBloc extends IBloc<any> {
  formData: ICountryFormViewData;
  loading: boolean;
  loadingBtn: boolean;
  error: string | null;
  onSubmit(e?: any): void;
  onCancel(e?: any): void;
  onClose(e?: any): void;
}

const statuses: [string, string][] = [
    [AvailabilityStatus.Available, "Available"],
    [AvailabilityStatus.ComingSoon, "Coming Soon"],
    [AvailabilityStatus.Unavailable, "Unavailable"],
]

@injectable()
export class CountryAddBloc implements ICountryAddBloc {
  @inject(CountryServiceSymbol) countryService!: ICountryService;
  @inject(DialogServiceSymbol) dialogService!: IDialogService;
  @inject(UserServiceSymbol) userService!: IUserService;
  @inject(PriceListServiceSymbol) pricelistService!: IPriceListService;
  @inject(UserStoreSymbol) userStore!: IUserStore;
  @inject(PriceListStoreSymbol) priceListStore!: IPriceListStore;

  @observable private _loading: boolean = true;
  @observable private _loadingBtn: boolean = false;
  @observable private _error: string | null = null;

  @observable private _pricelists: PriceList[] = [];
  @observable private _admins: User[] = [];

  @observable
  private _validator = new ObservableValidateAutomate(CountryInput);

  constructor() {
    makeObservable(this);
  }

  mount(props: any): void {
    this.refreshSelectableFields()
  }

  @computed
  public get formData(): ICountryFormViewData {
    return {
      country: {
        value: this._validator.values.country ?? "",
        error: this._validator.errors.country,
        onChange: this._validator.update.country,
      },
      status: {
        id: "status",
        label: "Availability Status",
        values: statuses,
        value: this._validator.values.status ?? "",
        error: this._validator.errors.status,
        onChange: this._validator.update.status,
      },
      pricelist: {
        id: "pricelist",
        label: "Pricelist",
        values: this.pricelistSelectableInput,
        value: this._validator.values.pricelist ?? "",
        error: this._validator.errors.pricelist,
        onChange: this._validator.update.pricelist,
      },
      emergencyPhone: {
        value: this._validator.values.emergencyPhone ?? "",
        error: this._validator.errors.emergencyPhone,
        onChange: this._validator.update.emergencyPhone,
      },
      doctorTemplate: {
        id: "doctorTemplate",
        label: "Upload Doctor Template",
        accept: ".pdf",
        value: this._validator.values.doctorTemplate,
        errorText: this._validator.errors.doctorTemplate,
        onChange: this._validator.update.doctorTemplate,
      },
      patientTemplate: {
        id: "patientTemplate",
        label: "Upload Patient Template",
        accept: ".pdf",
        value: this._validator.values.patientTemplate,
        errorText: this._validator.errors.patientTemplate,
        onChange: this._validator.update.patientTemplate,
      },
      childTemplate: {
        id: "childTemplate",
        label: "Upload Child Template",
        accept: ".pdf",
        value: this._validator.values.childTemplate,
        errorText: this._validator.errors.childTemplate,
        onChange: this._validator.update.childTemplate,
      },
      responsibleParty: {
        id: "responsibleParty",
        label: "Responsible Party",
        values: this.adminSelectableInput,
        value: this._validator.values.responsibleParty ?? "",
        error: this._validator.errors.responsibleParty,
        onChange: this._validator.update.responsibleParty,
      },
      stripePublicKey: {
        value: this._validator.values.stripePublicKey ?? "",
        error: this._validator.errors.stripePublicKey,
        onChange: this._validator.update.stripePublicKey,
      },
      stripeSecretKey: {
        value: this._validator.values.stripeSecretKey ?? "",
        error: this._validator.errors.stripeSecretKey,
        onChange: this._validator.update.stripeSecretKey,
      },
      stripeWebhookSecret: {
        value: this._validator.values.stripeWebhookSecret ?? "",
        error: this._validator.errors.stripeWebhookSecret,
        onChange: this._validator.update.stripeWebhookSecret,
      },
    };
  }
  @computed
  public get loading(): boolean {
    return this._loading;
  }

  @computed
  public get loadingBtn(): boolean {
    return this._loadingBtn;
  }

  @computed
  public get error(): string | null {
    return this._error;
  }

  @computed
  private get pricelistSelectableInput(): [string, string][] {
    return this._pricelists.map(pricelist => {
        return [pricelist.uid, `${pricelist.currency} - ${pricelist.description}`]
    })
  }

  @computed
  private get adminSelectableInput(): [string, string][] {
    return this._admins.map(admin => {
        return [admin.uid, admin.email]
    })
  }

  @computed
  public get admins(): User[] {
    return this._admins;
  }


  public onSubmit = async (e: any) => {
    e.preventDefault();
    if (!this._validator.validate()) {
      return;
    }

    runInAction(() => (this._loadingBtn = true));

    try {
      const data = this._validator.read();
      await this.countryService.addCountry(data);
      this.dialogService.close();
    } catch (err: any) {
      throw new Error(err.message);
    } finally {
      runInAction(() => (this._loadingBtn = false));
      this._validator.reset();
    }
  };

  public onCancel = (e?: any) => {
    this.dialogService.close();
  };

  public onClose = (e?: any) => {
    this.dialogService.close();
  };

  private async refreshSelectableFields() {
    try {
      await this.userService.fetch(undefined, undefined, `role="admin"`);
      await this.pricelistService.fetch();
    } catch (e: any) {
      runInAction(() => {
        this._error = e.message;
      });
    } finally {
      runInAction(() => {
        this._pricelists = this.priceListStore.data;
        this._admins = this.userStore.data;
      });
      if (this._pricelists.length === 0 || this._admins.length === 0) {
        runInAction(() => {
          this._error =
            "Error occured. Fetched empty data for obligatory select fields";
        });
      }
      runInAction(() => {
        this._validator.reset();
        this._loading = false;
      });
    }
  }
}
