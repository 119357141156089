import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import {
  IColumn,
  IGridProps,
  IGridRefreshInput,
} from "../../../components/grid";
import { IBloc } from "../../../ioc";
import { ISortBy, Consultation, ConsultationState } from "../../../models";
import {
  IConsultationService,
  ConsultationServiceSymbol,
} from "../../../services";
import { IConsultationStore, ConsultationStoreSymbol } from "../../../stores";
import { timeElapsed } from "../../../utils/time-elapsed";

export interface IConsultationsProps {
  start?: number;
  limit?: number;
  filter?: any;
  sortBy?: ISortBy;
}

export interface IActiveConsultationsBloc extends IBloc<any> {
  error: string;
  gridData: IGridProps;
}

const activeColumns: IColumn<Consultation>[] = [
  {
    field: "patientName",
    headerName: "Patient Name",
    valueGetter: ({ row }) =>
      row.patient ? `${row.patient.firstName} ${row.patient.lastName}` : '',
  },{
    field: 'doctorName',
    headerName: 'Doctor Name',
    valueGetter: ({row}) => row.doctor ? `${row.doctor.firstName} ${row.doctor.lastName}` : '-'
  },{
    field: "medicalCare",
    headerName: "Medical Care",
  },{
    field: "location",
    headerName: "Location",
  },{
    field: "state",
    headerName: "State",
  },{
    field: "startDate",
    headerName: "Start Date",
    valueGetter: ({ row }) => {
      return row.startDate != null ? timeElapsed(row.startDate) ?? "" : "";
    },
  },{
    field: "endDate",
    headerName: "End Date",
    valueGetter: ({ row }) => {
      return row.endDate != null ? timeElapsed(row.endDate) ?? "" : "";
    },
  },
];

@injectable()
export class ActiveConsultationsBloc implements IActiveConsultationsBloc {
  @inject(ConsultationServiceSymbol)
  private readonly consultationService!: IConsultationService;
  @inject(ConsultationStoreSymbol)
  private readonly consultationStore!: IConsultationStore;

  @observable private _loading: boolean = true;
  @observable private _error: string = "";
  @observable private _start: number = 0;
  @observable private _limit: number = 10;

  //TODO add filter and sortBy

  constructor() {
    makeObservable(this);
  }

  @computed
  public get error(): string {
    return this._error;
  }

  @computed
  public get gridData(): IGridProps {
    return {
      error: this._error,
      loading: this._loading,
      columns: activeColumns,
      data: this.consultationStore.data,
      count: this.consultationStore.count,
      page: Math.floor(this._start / this._limit),
      pageSize: this._limit,
      onRowClick: this.handleOnRowClick,
      onRefresh: this.handleRefresh,
    };
  }

  public mount(props: IConsultationsProps): void {
    this.refresh(props);
  }

  private handleRefresh = ({
    page,
    pageSize,
    filter,
    sortBy,
  }: IGridRefreshInput) => {
    const props: IConsultationsProps = {
      start: page * pageSize,
      limit: pageSize,
      filter,
      sortBy,
    };
    this.refresh(props);
  };

  private handleOnRowClick = (uid: string) => (e: any) => {};

  private async refresh({
    start,
    limit,
    sortBy,
    filter,
  }: IConsultationsProps): Promise<void> {
    runInAction(() => {
      if (start != undefined) {
        this._start = start;
      }
      if (limit != undefined) {
        this._limit = limit;
      }
    });

    if (!filter) {
      filter = "";
    }

    filter = `state="${ConsultationState.Waiting}"||state="${ConsultationState.Accepted}"||state="${ConsultationState.Pending}"||state="${ConsultationState.Report}"`;

    try {
      await this.consultationService.fetch(start, limit, filter, sortBy);
    } catch (err: any) {
      console.error(err);
      runInAction(() => {
        this._error = err.message;
      });
    } finally {
      runInAction(() => {
        this._loading = false;
      });
    }
  }
}
