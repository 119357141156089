import { memo } from "react";
import { Observer } from "mobx-react-lite";
import { useBloc } from "../ioc";
import { CountryUpdateBloc } from "./country-update.bloc";
import { FileUploader } from "../components/file-uploader";
import { SelectableInput } from "../components/select-input";
import { TextField } from "../components/text-field";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export const CountryUpdateDialog = memo((props) => {
  const bloc = useBloc(CountryUpdateBloc, props);
  return (
    <Observer>
      {() => {
        const {
          formData,
          loading,
          error,
          loadingBtn,
          onClose,
          onSubmit,
          onCancel,
        } = bloc;

        if (error) {
          return (
            <Dialog open={true} onClose={onClose}>
              <div style={{ color: "red" }}>{error}</div>
            </Dialog>
          );
        }
        if (loading) {
          return (
            <Dialog open={true} onClose={onClose}>
              <div>Loading...</div>
            </Dialog>
          );
        }

        return (
          <Dialog
            open={true}
            onClose={onClose}
            maxWidth={'md'}
            fullWidth={true}
          >
            <DialogTitle> Update country </DialogTitle>
            <Box component="form" onSubmit={onSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField
                    focused
                    id="country"
                    label="Country"
                    {...formData.country}
                  />
                  <SelectableInput {...formData.status} />
                  <SelectableInput {...formData.pricelist} />

                  <TextField
                    id="emergencyPhone"
                    label="Emergency Phone"
                    {...formData.emergencyPhone}
                  />
                  <FileUploader {...formData.doctorTemplate} />
                  <FileUploader {...formData.patientTemplate} />
                  <FileUploader {...formData.childTemplate} />

                  <SelectableInput {...formData.responsibleParty} />

                  <TextField
                    id="stripePublicKey"
                    label="Stripe Public Key"
                    {...formData.stripePublicKey}
                  />
                  <TextField
                    id="stripeSecretKey"
                    label="Stripe Secret Key"
                    {...formData.stripeSecretKey}
                  />
                  <TextField
                    id="stripeWebhookSecret"
                    label="Stripe Webhook Secret"
                    {...formData.stripeWebhookSecret}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  loading={loadingBtn}
                  type="submit"
                  fullWidth
                >
                  Submit
                </LoadingButton>
                <Button onClick={onCancel}>Cancel</Button>
              </DialogActions>
            </Box>
          </Dialog>
        );
      }}
    </Observer>
  );
});
