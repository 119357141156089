export const timeElapsed = (d: Date) => {
    if(!(d instanceof Date)) return
    const ms = d.getTime();
    const now = Date.now();
    const elapsed = now - ms
    if (elapsed < 60*1000) return 'just now'
    else if (elapsed < 2*60*1000) return `${Math.floor(elapsed / 1000 / 60 )} min ago`
    else if (elapsed < 60*60*1000) return `${Math.floor(elapsed / 1000 / 60 )} mins ago`
    else if (elapsed < 2*60*60*1000) return `${Math.floor(elapsed / 1000 / 60 / 60)} hour ago`
    else if (elapsed < 24*60*60*1000) return `${Math.floor(elapsed / 1000 / 60 / 60)} hours ago`
    else if (elapsed < 2*24*60*60*1000) return 'yesterday'
    else return d.toLocaleDateString()
}