import { memo } from "react";
import { useBloc } from "../../../ioc";
import { RegionBloc } from "./region.bloc";
import { Grid, ICollapsibleRowProps } from "../../../components/grid";
import { Observer } from "mobx-react-lite";
import { Region } from "../../../models";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const collapsibleRowRenderer = ({
  row,
  collapsibleRowItemActions,
  collapsibleRowItemValue,
}: ICollapsibleRowProps<Region>) => {
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Details
      </Typography>
      <Table size="small" aria-label="countryDetails">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f4f4f4" }}>
            <TableCell key="field">Field</TableCell>
            <TableCell key="value">Value</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow key={`pricelist`}>
            <TableCell key="field">Pricelist</TableCell>
            <TableCell key="value">
              <Button
                sx={{ backgroundColor: "#f4f4f4" }}
                onClick={collapsibleRowItemActions?.(
                  "pricelistUid",
                  row.pricelistUid
                )}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
          <TableRow key={`coordinates`}>
            <TableCell key="field">Coordinates</TableCell>
            <TableCell key="value">{`${row.coordinates.latitude}, ${row.coordinates.longitude}`}</TableCell>
          </TableRow>
          <TableRow key={`radius`}>
            <TableCell key="field">Radius</TableCell>
            <TableCell key="value">
              {collapsibleRowItemValue?.("radius", row.radius) ?? ""}
            </TableCell>
          </TableRow>
          <TableRow key={`zipCodes`}>
            <TableCell key="field">Zip Codes</TableCell>
            <TableCell key="value">
              {collapsibleRowItemValue?.("zipCodes", row.zipCodes) ?? ""}
            </TableCell>
          </TableRow>
          <TableRow key={`responsibleParty`}>
            <TableCell key="field">Responsible Party</TableCell>
            <TableCell key="value">
              {collapsibleRowItemValue?.(
                "responsiblePartyUid",
                row.responsiblePartyUid
              ) ?? ""}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export const RegionPage = memo((props: any) => {
  const bloc = useBloc(RegionBloc, { ...props });
  return (
    <Observer>
      {() => {
        const { gridData } = bloc;
        let grid = { ...gridData };

        grid.collapsibleRowRenderer = collapsibleRowRenderer;
        
        grid.columns = [
          ...grid.columns,
          {
            field: "actions",
            headerName: "Actions",
            renderer: ({ row }) => {
              return (
                <>
                  <Button onClick={bloc.onRegionEdit(row.uid)}>Edit</Button>
                </>
              );
            },
          },
        ];

        return (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{ backgroundColor: "#f4f4f4", mb: "0.5em", mt: "0.5em" }}
                onClick={bloc.onAddNewRegion()}
              >
                Add a region
              </Button>
            </Box>
            <Grid {...grid} />
          </Box>
        );
      }}
    </Observer>
  );
});
