import {memo} from 'react';
import { Outlet, Link } from 'react-router-dom'
import { Observer } from 'mobx-react-lite'
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useRouteMatch } from '../../../utils/use-route-match';

enum LocalizationsRoutes {
  Pricelists = '/localization/pricelists',
  Countries = '/localization/countries',
  Regions = '/localization/regions'
}

export const LocalizationHeader = memo((props: any) => {

  const routeMatch = useRouteMatch([LocalizationsRoutes.Pricelists, LocalizationsRoutes.Countries, LocalizationsRoutes.Regions]);
  const currentTab = routeMatch?.pattern?.path ?? LocalizationsRoutes.Pricelists;

  return (
      <Observer>
        {() => {
          return (
              <Box sx={{width: '100%'}}>
                  <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={currentTab}>
                      <Tab label="Pricelists" value={LocalizationsRoutes.Pricelists} to={LocalizationsRoutes.Pricelists} component={Link}/>
                      <Tab label="Countries" value={LocalizationsRoutes.Countries} to={LocalizationsRoutes.Countries} component={Link}/>
                      <Tab label="Regions" value={LocalizationsRoutes.Regions} to={LocalizationsRoutes.Regions} component={Link}/>
                    </Tabs>
                  </Box>
                <Outlet />
              </Box>
          )
        }}
      </Observer>
  )
})