import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IBloc } from "../ioc";
import { ObservableValidateAutomate } from '../utils/observable-validation-automate';
import {
  IPriceListService,
  PriceListServiceSymbol,
  DialogServiceSymbol,
  IDialogService,
} from "../services";
import { IPriceListStore, PriceListStoreSymbol } from "../stores";
import { PriceListInput } from "../models";

interface IFormTextField {
  value?: string;
  error: string | null | undefined;
  onChange: (v: string | any) => void;
}

interface IPriceListFormViewData {
  pediatricianPrice: IFormTextField;
  familyInternistPrice: IFormTextField;
  entPrice: IFormTextField;
  woundCarePrice: IFormTextField;
  surgeonPrice: IFormTextField;
  painManagementPrice: IFormTextField;
  obGynPrice: IFormTextField;
  psychologyPrice: IFormTextField;
  currency: IFormTextField;
  description: IFormTextField;
  cancellationFee: IFormTextField;
  serviceFee: IFormTextField;
}

interface IAvailabilityField {
  value: boolean;
  onChange: (v: string | any) => void;
}

interface IAvailability {
  pediatrician: IAvailabilityField;
  familyInternist: IAvailabilityField;
  ent: IAvailabilityField;
  woundCare: IAvailabilityField;
  surgeon: IAvailabilityField;
  painManagement: IAvailabilityField;
  obGyn: IAvailabilityField;
  psychology: IAvailabilityField;
}

export interface IPriceListUpdateProps {
  uid: string;
  onSubmit?: () => void;
}

export interface IPriceListUpdateBloc extends IBloc<any> {
  formData: IPriceListFormViewData;
  loading: boolean;
  availability: IAvailability;
  onSubmit(e?: any): void;
  onCancel(e?: any): void;
  onClose(e?: any): void;
}

@injectable()
export class PriceListUpdateBloc implements IPriceListUpdateBloc {
  @inject(PriceListServiceSymbol) pricelistService!: IPriceListService;
  @inject(PriceListStoreSymbol) pricelistStore!: IPriceListStore;
  @inject(DialogServiceSymbol) dialogService!: IDialogService;

  @observable
  private _loading = false;

  @observable
  private _uid: string | null = null;

  @observable
  private _availability = {
    pediatrician: true,
    familyInternist: true,
    ent: true,
    woundCare: true,
    surgeon: true,
    painManagement: true,
    obGyn: true,
    psychology: true,
  };

  @observable
  private _validator = new ObservableValidateAutomate(PriceListInput);

  constructor() {
    makeObservable(this);
  }

  mount({ uid }: IPriceListUpdateProps): void {
    if (this._uid === uid || !uid) {
      return;
    }

    const pricelist = this.pricelistStore.find(uid);
    if (!pricelist)
      throw new Error(`Could not find a pricelist to update by uid: ${uid}`);

    runInAction(() => {
      this._uid = uid;
      
      const {uid: ignored, createdAt, createdBy, updatedAt, updatedBy, ...validatorData} = pricelist

      this._validator.reset().update({...validatorData});
    

      this._availability = {
        pediatrician: pricelist.pediatricianPrice === null ? false : true,
        familyInternist: pricelist.familyInternistPrice === null ? false : true,
        ent: pricelist.entPrice === null ? false : true,
        woundCare: pricelist.woundCarePrice === null ? false : true,
        surgeon: pricelist.surgeonPrice === null ? false : true,
        painManagement: pricelist.painManagementPrice === null ? false : true,
        obGyn: pricelist.obGynPrice === null ? false : true,
        psychology: pricelist.psychologyPrice === null ? false : true,
      };
    });
  }

  @computed
  public get formData(): IPriceListFormViewData {
    return {
      pediatricianPrice: {
        value: this._validator.values.pediatricianPrice?.toString() ?? '0',
        error: this._validator.errors.pediatricianPrice,
        onChange: this._validator.update.pediatricianPrice,
      },
      familyInternistPrice: {
        value: this._validator.values.familyInternistPrice?.toString()  ?? '0',
        error: this._validator.errors.familyInternistPrice,
        onChange: this._validator.update.familyInternistPrice,
      },
      entPrice: {
        value: this._validator.values.entPrice?.toString()  ?? '0',
        error: this._validator.errors.entPrice,
        onChange: this._validator.update.entPrice,
      },
      woundCarePrice: {
        value: this._validator.values.woundCarePrice?.toString() ?? '0',
        error: this._validator.errors.woundCarePrice,
        onChange: this._validator.update.woundCarePrice,
      },
      surgeonPrice: {
        value: this._validator.values.surgeonPrice?.toString() ?? '0',
        error: this._validator.errors.surgeonPrice,
        onChange: this._validator.update.surgeonPrice,
      },
      painManagementPrice: {
        value: this._validator.values.painManagementPrice?.toString() ?? '0',
        error: this._validator.errors.painManagementPrice,
        onChange: this._validator.update.painManagementPrice,
      },
      obGynPrice: {
        value: this._validator.values.obGynPrice?.toString() ?? '0',
        error: this._validator.errors.obGynPrice,
        onChange: this._validator.update.obGynPrice,
      },
      psychologyPrice: {
        value: this._validator.values.psychologyPrice?.toString() ?? '0',
        error: this._validator.errors.psychologyPrice,
        onChange: this._validator.update.psychologyPrice,
      },
      currency: {
        value: this._validator.values.currency,
        error: this._validator.errors.currency,
        onChange: this._validator.update.currency,
      },
      description: {
        value: this._validator.values.description,
        error: this._validator.errors.description,
        onChange: this._validator.update.description,
      },
      cancellationFee: {
        value: this._validator.values.cancellationFee?.toString() ?? '0',
        error: this._validator.errors.cancellationFee,
        onChange: this._validator.update.cancellationFee,
      },
      serviceFee: {
        value: this._validator.values.serviceFee?.toString() ?? '0',
        error: this._validator.errors.serviceFee,
        onChange: this._validator.update.serviceFee,
      },
    };
  }

  @computed
  public get availability() {
    return {
      pediatrician: {
        value: this._availability.pediatrician,
        onChange: this.onSwitchAvailable("pediatrician"),
        label: this._availability.pediatrician ? "enabled" : "disabled",
      },
      familyInternist: {
        value: this._availability.familyInternist,
        onChange: this.onSwitchAvailable("familyInternist"),
        label: this._availability.familyInternist ? "enabled" : "disabled",
      },
      ent: {
        value: this._availability.ent,
        onChange: this.onSwitchAvailable("ent"),
        label: this._availability.ent ? "enabled" : "disabled",
      },
      woundCare: {
        value: this._availability.woundCare,
        onChange: this.onSwitchAvailable("woundCare"),
        label: this._availability.woundCare ? "enabled" : "disabled",
      },
      surgeon: {
        value: this._availability.surgeon,
        onChange: this.onSwitchAvailable("surgeon"),
        label: this._availability.surgeon ? "enabled" : "disabled",
      },
      painManagement: {
        value: this._availability.painManagement,
        onChange: this.onSwitchAvailable("painManagement"),
        label: this._availability.painManagement ? "enabled" : "disabled",
      },
      obGyn: {
        value: this._availability.obGyn,
        onChange: this.onSwitchAvailable("obGyn"),
        label: this._availability.obGyn ? "enabled" : "disabled",
      },
      psychology: {
        value: this._availability.psychology,
        onChange: this.onSwitchAvailable("psychology"),
        label: this._availability.psychology ? "enabled" : "disabled",
      },
    };
  }

  @computed
  public get loading(): boolean {
    return this._loading;
  }

  public onSubmit = async (e: any) => {
    e.preventDefault();
    if (!this._uid) {
      return;
    }
    if (!this._validator.validate()) {
      return;
    }

    runInAction(() => (this._loading = true));

    try {
      const data = this._validator.read();
      await this.pricelistService.updatePriceList(this._uid, data);
      this.dialogService.close();
    } catch (err: any) {
      throw new Error(err.message);
    } finally {
      runInAction(() => (this._loading = false));
      this._validator.reset();
    }
  };

  public onCancel = (e?: any) => {
    this.dialogService.close();
  };

  public onClose = (e?: any) => {
    this.dialogService.close();
  };

  private onSwitchAvailable = (prop: keyof IAvailability) => (e: any) => {
    const value = e.target.checked;
    const formDataProp = `${prop}Price`;
    if (!value) this._validator.update({ [formDataProp]: null });
    else this._validator.update({ [formDataProp]: 0 });

    runInAction(() => {
      this._availability[prop] = value;
    });
  };
}
