import { readable } from '@ts-awesome/model-reader'

import { MedicalCareType } from './medical-care-type'
import { Coordinate } from './coordinate'
import { Doctor, Patient } from './user'

export enum ConsultationState {
  WaitingForPayment = 'waiting-for-payment',
  Waiting = 'waiting',
  Accepted = 'accepted',
  Pending = 'pending',
  Report = 'report',
  Finished = 'finished',
  Cancelled = 'cancelled',
}


export class Consultation {
  @readable(String) uid!: string

  @readable(Patient) patient!: Patient
  @readable(Doctor, true) doctor!: Doctor

  @readable(String) medicalCare!: MedicalCareType
  @readable([String]) symptoms!: string[]
  @readable(String) notes!: string
  @readable(String) location!: string
  @readable(Coordinate) coordinate!: Coordinate
  @readable(Boolean) parkingAvailable!: boolean
  @readable(String) state!: ConsultationState
  @readable(Date, true) startDate?: Date | null
  @readable(Date, true) endDate?: Date | null

  @readable([String]) photos!: string[]

  @readable(Date) createdAt!: Date
  @readable(Date) updatedAt!: Date
}
