import { memo, useCallback } from 'react'
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

export interface ISelectField<T = string, K = string> {
  value?: T
  label: K
  values?: [T, K][]
  error: string | null | undefined
  onChange: (v: string | any) => void
}
export type ISelect<T = string> = ISelectField<T> &
  Omit<SelectProps, 'error' | 'onChange' | 'value' | 'label'>

export const SelectableInput = memo<ISelect>((props) => {
  const { id, label, value, values, error, onChange, style, ...otherProps } =
    props
  const handleChange = useCallback(
    (e: SelectChangeEvent<unknown>) => onChange?.(e.target.value),
    [onChange]
  )

  return (
    <FormControl>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={`${id}-select-label`}
        id={`$${id}-select`}
        value={values?.length ? value : ''}
        label={label}
        error={!!error}
        onChange={handleChange}
        {...otherProps}
      >
        {values?.map(([value, label]) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})
