import { readable } from '@ts-awesome/model-reader'
import { Coordinates } from './coordinates'

export enum UserRole {
  Patient = 'patient',
  Doctor = 'doctor',
  Admin = 'admin'
}

export enum PatientGender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
}

export class Patient {
  @readable(String) uid!: string
  @readable(String) accountUid!: string
  @readable(String) firstName!: string
  @readable(String) lastName!: string
  @readable(Date) createdAt!: Date
  @readable(Date) updatedAt!: Date
  @readable(Date, true) birthday!: Date | null
  @readable(String, true) language!: string | null
  @readable(String, true) gender!: PatientGender | null
  @readable(String, true) guardianName!: string | null
  @readable(Date, true) guardianBirthday!: Date | null
  @readable(String, true) guardianPhone!: string | null
}

export class DoctorLocation {
  @readable(Coordinates) coordinate!: Coordinates
  @readable(Number) radius!: number
}
export class Doctor {
  @readable(String) firstName!: string
  @readable(String) lastName!: string
  @readable(String) speciality!: string
  @readable(String) education!: string
  @readable(String, true) bio!: string | null
  @readable(Date) createdAt!: Date
  @readable(Date) updatedAt!: Date
  @readable(Date, true) approvedAt!: Date | null
  @readable(String, true) approvedBy!: string | null
  @readable(String, true) photoUid!: string | null
  @readable(String, true) documentUid!: string | null
  @readable(DoctorLocation, true) doctorLocation!: DoctorLocation | null
}

export class User {
  @readable(String) uid!: string
  @readable(String) phone!: string
  @readable(String) email!: string
  @readable(String) role!: UserRole
  @readable(Date, true) verifiedAt!: Date | null
  @readable(Date) createdAt!: Date
  @readable(Date) updatedAt!: Date

  @readable([Patient], true) patients?: Patient[] | null
  @readable(Doctor, true) doctor?: Doctor | null
}
