import { inject, injectable } from "inversify";
import { IConsultationApi, ConsultationApiSymbol } from "../api";
import { IFetchFilter, ISortBy } from "../models";
import { IConsultationStore, ConsultationStoreSymbol } from "../stores";

export interface IConsultationService {
  fetch(start?: number, limit?: number, filter?: IFetchFilter, sortBy?: ISortBy): Promise<void>;
}

export const ConsultationServiceSymbol = Symbol.for('IConsultationService');

@injectable()
export class ConsultationService implements IConsultationService {
  
  @inject(ConsultationApiSymbol)
  private api!: IConsultationApi;

  @inject(ConsultationStoreSymbol)
  private store!: IConsultationStore;
  
  public async fetch(start?: number, limit?: number, filter?: IFetchFilter, sortBy?: ISortBy): Promise<void> {
    const [data, count] = await Promise.all([
      this.api.list(start, limit, filter, sortBy),
      this.api.count(filter)
    ]);
    this.store.reset(data, count);
  }
}