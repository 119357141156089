import { injectable } from "inversify";
import { action, computed, makeObservable, observable } from "mobx";
import { INotification } from "../models";

export interface INotificationStore {
  readonly notification: INotification | null;
  set(notification: INotification): void;
}

export const NotificationStoreSymbol = Symbol.for('INotificationStore');

@injectable()
export class NotificationStore implements INotificationStore {

  @observable private _notification!: INotification | null; 

  constructor() {
    makeObservable(this);
    this._notification = null;
  }

  @action
  public set(notification: INotification): void {
    this._notification = notification;
  }

  @computed
  get notification(): INotification | null {
    return this._notification
  }
}