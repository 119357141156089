import {
  validate,
  presence,
  numericality,
  length,
  alphaNumeric,
  inclusion,
} from "@ts-awesome/validate";
import { readable } from "@ts-awesome/model-reader";
import { CurrentISOList } from '../../utils/currencies'

export class PriceListInput {
  @validate([
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number, true)
  public pediatricianPrice!: number | null;

  @validate([
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number, true)
  public familyInternistPrice!: number | null;

  @validate([
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number, true)
  public entPrice!: number | null;

  @validate([
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number, true)
  public woundCarePrice!: number | null;

  @validate([
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number, true)
  public surgeonPrice!: number | null;

  @validate([
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number, true)
  public painManagementPrice!: number | null;

  @validate([
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number, true)
  public obGynPrice!: number | null;

  @validate([
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number, true)
  public psychologyPrice!: number | null;

  @validate([presence(), alphaNumeric(false), length({ is: 3 }), inclusion(CurrentISOList)])
  @readable(String)
  public currency!: string;

  @validate([presence(), length({ maximum: 128 })])
  @readable(String)
  public description!: string;

  @validate([
    presence(),
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number)
  public cancellationFee!: number;

  @validate([
    presence(),
    numericality({ onlyInteger: true, greaterThanOrEqualTo: 0 }),
  ])
  @readable(Number)
  public serviceFee!: number;
}
