import 'reflect-metadata';
//import 'source-map-support/register';

import 'ts-polyfill/lib/es2019-array';
import 'ts-polyfill/lib/es2019-object';
import 'ts-polyfill/lib/es2019-string';
import 'ts-polyfill/lib/es2019-symbol';
import 'ts-polyfill/lib/es2020-string';

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import {App} from './App';
import { Container } from 'inversify';
import { setContainer } from './ioc';
import { setUpRootIoC } from './ioc/root.scope';
import reportWebVitals from './reportWebVitals';

const container = new Container();
setContainer(container);
setUpRootIoC(container);

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Element with id root is not fount');
}
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
