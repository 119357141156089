import { inject, injectable } from "inversify";
import { IRegionApi, RegionApiSymbol } from "../api";
import { RegionInput, IFetchFilter, ISortBy } from "../models";
import { IRegionStore, RegionStoreSymbol } from "../stores";

export interface IRegionService {
  fetch(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<void>;
  updateRegion(uid: string, input: RegionInput): Promise<void>;
  addRegion(input: RegionInput): Promise<void>;
}

export const RegionServiceSymbol = Symbol.for("IRegionService");

@injectable()
export class RegionService implements IRegionService {
  @inject(RegionApiSymbol)
  private regionApi!: IRegionApi;

  @inject(RegionStoreSymbol)
  private regionStore!: IRegionStore;

  public async fetch(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<void> {
    const [data, count] = await Promise.all([
      this.regionApi.list(start, limit, filter, sortBy),
      this.regionApi.count(filter),
    ]);
    this.regionStore.reset(data, count);
  }

  public async updateRegion(
    uid: string,
    input: RegionInput
  ): Promise<void> {
    const region = await this.regionApi.putRegion(uid, input);
    this.regionStore.refresh([region]);
  }

  public async addRegion(input: RegionInput): Promise<void>{
    const region = await this.regionApi.postRegion(input)
    this.regionStore.refresh([region]);
  }
}