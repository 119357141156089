import {inject, injectable} from "inversify";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {IBloc} from "../ioc";
import {ValidateAutomate} from "@ts-awesome/validate";
import {
  IUserService,
  UserServiceSymbol,
  // INavigationService,
  // NavigationServiceSymbol,
  DialogServiceSymbol,
  IDialogService
} from "../services";
import {IUserStore, UserStoreSymbol} from "../stores";
import {DoctorInput} from "../models";

interface IFormField {
  value: string;
  error: string | null | undefined;
  onChange: (v: string | any) => void;
}

interface IFormCheckbox {
  onChange: (v: string | any) => void;
  checked: boolean;
}

interface IDoctorFormViewData {
  firstName: IFormField;
  lastName: IFormField;
  speciality: IFormField;
  approved: IFormCheckbox;
}

export interface IDoctorUpdateProps {
  uid: string;
  onSubmit?: () => void;
}

export interface IDoctorUpdateBloc extends IBloc<any> {
  formData: IDoctorFormViewData;
  saveEnabled: boolean;
  loading: boolean;
  error: string;

  onSubmit(e?: any): void;

  onCancel(e?: any): void;

  onClose(e?: any): void;
}

@injectable()
export class DoctorUpdateBloc implements IDoctorUpdateBloc {

  @inject(UserServiceSymbol)
  userService!: IUserService;
  @inject(UserStoreSymbol)
  userStore!: IUserStore;
  @inject(DialogServiceSymbol)
  dialogService!: IDialogService;

  @observable
  private _apiError = '';

  @observable
  private _loading = false;

  @observable
  private _saveEnabled: boolean = true;

  @observable
  private _uid: string | null = null;

  @observable
  private _formData: DoctorInput = {
    firstName: '',
    lastName: '',
    speciality: '',
    approved: false
  };

  @observable
  private _errors: Partial<Record<keyof DoctorInput, string | null | undefined>> = {
    firstName: null,
    lastName: null,
    speciality: null,
  };

  @observable
  public doctorAlreadyApproved = false

  private _validator: ValidateAutomate<DoctorInput> | null = null;

  constructor() {
    makeObservable(this);
  }

  mount({uid}: IDoctorUpdateProps): void {
    if (this._uid === uid || !uid) {
      return;
    }

    const user = this.userStore.find(uid);
    runInAction(() => {
      this._uid = uid;
      this._formData = {
        firstName: user?.doctor?.firstName ?? '',
        lastName: user?.doctor?.lastName ?? '',
        speciality: user?.doctor?.speciality ?? '',
        approved: !!user?.doctor?.approvedAt ?? false
      };
      this._errors = {
        firstName: null,
        lastName: null,
        speciality: null,
      };
      this.doctorAlreadyApproved = this._formData.approved;
    })

    this._validator = new ValidateAutomate(DoctorInput);
    this._validator.update({...this._formData}).reset();

    runInAction(() => {
      this._saveEnabled = this._validator?.attempted ? this._validator?.valid : true
    });
  }

  @computed
  public get formData(): IDoctorFormViewData {
    return {
      firstName: {
        value: this._formData.firstName,
        error: this._errors.firstName,
        // onChange: this.onChange('firstName')
        onChange: () => undefined,
      },
      lastName: {
        value: this._formData.lastName,
        error: this._errors.lastName,
        // onChange: this.onChange('lastName')
        onChange: () => undefined,
      },
      speciality: {
        value: this._formData.speciality,
        error: this._errors.speciality,
        // onChange: this.onChange('speciality')
        onChange: () => undefined,
      },
      approved:{
        checked: this._formData.approved,
        onChange: this.onChange('approved')
      }
    }
  }

  @computed
  public get saveEnabled(): boolean {
    return this._saveEnabled;
  }

  @computed
  public get loading(): boolean {
    return this._loading;
  }

  @computed
  public get error(): string {
    return this._apiError;
  }

  public onSubmit = async (e: any) => {
    e.preventDefault();
    if (!this._uid) {
      return;
    }
    if (this._validator?.validate()) {
    }

    runInAction(() => {
      this._errors = {...this._validator?.errors};
      this._saveEnabled = this._validator?.attempted ? this._validator?.valid : true;
    });

    if (Object.keys(this._errors).length) {
      return;
    }

    runInAction(() => this._loading = true);
    try {
      let data = this._validator?.read()!.approved!;
      await this.userService.approveDoctor(this._uid, data)
      this.dialogService.close();
    } catch (err: any) {
      runInAction(() => {
        this._apiError = err.message;
      });
    } finally {
      runInAction(() => this._loading = false);
    }

  }

  public onCancel = (e?: any) => {
    this.dialogService.close();
  }

  public onClose = (e?: any) => {
    this.dialogService.close();
  }

  private onChange = (prop: keyof DoctorInput) => (e: any) => {
    const value = e.target.checked ?? e.target.value;
    this._validator?.update({
      [prop]: value,
    });

    runInAction(() => {
      this._formData = {...this._formData, [prop]: value};
      this._errors = {...this._errors, [prop]: this._validator?.get(prop as never) ?? null};
      this._saveEnabled = this._validator?.attempted ? this._validator?.valid : true;
    });
  }

}