import React, { memo } from "react";
import { useBloc } from "../../../ioc";
import { PricelistsBloc } from "./pricelists.bloc";

import { Grid, ICollapsibleRowProps } from "../../../components/grid";
import { Observer } from "mobx-react-lite";
import { PriceList } from "../../../models";
import { CurrencyCents } from "../../../utils/currencies";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

type CollapsiblePriceListField =
  | "pediatricianPrice"
  | "familyInternistPrice"
  | "entPrice"
  | "woundCarePrice"
  | "surgeonPrice"
  | "painManagementPrice"
  | "obGynPrice"
  | "psychologyPrice"
  | "cancellationFee"
  | "serviceFee";

const collapsibleRowRenderer = ({ row }: ICollapsibleRowProps<PriceList>) => {
  const collapsibleRowFields: [CollapsiblePriceListField, string][] = [
    ["pediatricianPrice", "Pediatrician Price"],
    ["familyInternistPrice", "Family Internist Price"],
    ["entPrice", "Ent Price"],
    ["woundCarePrice", "Wound Care Price"],
    ["surgeonPrice", "Surgeon Price"],
    ["painManagementPrice", "Pain Management Price"],
    ["obGynPrice", "Ob Gyn Price"],
    ["psychologyPrice", "Psychology Price"],
    ["cancellationFee", "Cancellation Fee"],
    ["serviceFee", "Service Fee"],
  ];

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Prices
      </Typography>
      <Table size="small" aria-label="prices">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f4f4f4" }}>
            <TableCell key="field">Service</TableCell>
            <TableCell key="value">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collapsibleRowFields.map(([fieldname, label]) => {
            return (
              <TableRow key={`${fieldname}-field`}>
                <TableCell key="field">{label}</TableCell>
                <TableCell key="value">
                  {row[fieldname] !== null
                    ? `${(row[fieldname] as number)/(CurrencyCents[row.currency] ?? 1)} ${row.currency}`
                    : "unavailable"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export const PriceListsPage = memo((props: any) => {
  const bloc = useBloc(PricelistsBloc, { ...props });

  return (
    <Observer>
      {() => {
        const { gridData } = bloc;
        let grid = { ...gridData };

        grid.collapsibleRowRenderer = collapsibleRowRenderer;

        grid.columns = [
          ...grid.columns,
          {
            field: "actions",
            headerName: "Actions",
            renderer: ({ row }) => {
              return (
                <>
                  <Button onClick={bloc.onPricelistEdit(row.uid)}>Edit</Button>
                </>
              );
            },
          },
        ];

        return (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{ backgroundColor: "#f4f4f4", mb: "0.5em", mt: "0.5em" }}
                onClick={bloc.onAddNewPricelist()}
              >
                Add a pricelist
              </Button>
            </Box>
            <Grid {...grid} />
          </Box>
        );
      }}
    </Observer>
  );
});
