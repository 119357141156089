import React, {memo} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ISortBy } from '../models';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface IGridValueGetterParams<Model> {
  row: Model;
}

export interface IColumn<Model> {
  field: string;
  headerName: string;
  width?: number;
  align?: 'right' | 'left';
  valueGetter?: (params: IGridValueGetterParams<Model>) => string;
  renderer?: (props: IRowProps<any>) => any;
}

interface IGridRowProps<Model> {
  keyField: string;
  columns: IColumn<Model>[];
  row: Model;
  onRowClick?: (key: string) => (e: any) => void;
  collapsibleRowItemActions?: (column: keyof Model, val?: any)=>(e: any) => void;
  collapsibleRowItemValue?: (column: keyof Model, val?: any) => any; 
  collapsibleRowRenderer?: (props: ICollapsibleRowProps<any>) => any;
}

export const GridRow = memo((props: IGridRowProps<any>) => {
  const [open, setOpen] = React.useState(false);

  const {
    keyField,
    columns,
    row,
    collapsibleRowItemActions,
    collapsibleRowItemValue,
    // onRowClick,
    collapsibleRowRenderer} = props;
  const key = row[keyField];
  return (
    <>
      <TableRow
        hover
        // onClick={onRowClick(key)}
        tabIndex={-1}
        key={key}
        sx={collapsibleRowRenderer ? { '& > *': { borderBottom: 'unset', cursor: 'pointer' } } : {}}
      >
        {collapsibleRowRenderer ? (<TableCell key="collasable-button">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>) : <></>}

        {columns.map(col => {
          return (
            <TableCell align={col.align || 'left'} key={col.field}>
              {col.renderer ?  col.renderer({row}) : col.valueGetter ? col.valueGetter({row}) : row[col.field]}
            </TableCell>
          );
        })}
      </TableRow>

      {collapsibleRowRenderer ? (
        <TableRow key={`${key}-collasable-content`}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open}
                      timeout="auto"
                      unmountOnExit>
              {collapsibleRowRenderer({row, collapsibleRowItemActions, collapsibleRowItemValue})}
            </Collapse>
          </TableCell>
        </TableRow>
      ) : <></>}
    </>
  )
})

interface GridHeadProps<Model> {
  collapsibleRowRenderer:any;
  columns: IColumn<Model>[];
  orderBy?: ISortBy;
}

export const GridHead = memo((props: GridHeadProps<any>) => {
  const { columns, orderBy, collapsibleRowRenderer } = props;
  // const createSortHandler =
  //   (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
  //     onRequestSort(event, property);
  //   };
  return (
    <TableHead>
      <TableRow>
        {collapsibleRowRenderer  && <TableCell/> }
        {columns.map((col) => {
          const orderField = orderBy && orderBy[col.field];
          return (
            <TableCell
              key={col.field}
              align={col.align || 'left'}
              padding={'normal'}
              sortDirection={orderField ? orderBy[col.field] : false }
            >
              <TableSortLabel
                active={!!orderField}
                direction={orderField || 'asc'}
                // onClick={createSortHandler(headCell.id)}
              >
                {col.headerName}
                {/* {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null} */}
                {' '}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
})

const EmptyGridBody = memo(({children, colSize}: {children: any, colSize: number}) => (
  <TableRow>
    <TableCell colSpan={colSize}>
      <Box sx={{
        textAlign: 'center',
        minHeight: 300,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        }}
      >
        {children}
      </Box>
    </TableCell>
  </TableRow>
))

export interface IGridRefreshInput {
  page: number;
  pageSize: number;
  filter?: any;
  sortBy?: ISortBy;
}

export interface IRowProps<T> {
  row: T;
}

export interface ICollapsibleRowProps<T> extends IRowProps<T> {
  collapsibleRowItemActions?: (column: keyof T, val?: any)=>(e: any) => void;
  collapsibleRowItemValue?: (column: keyof T, val?: any) => any
}

export interface IGridProps {
  loading?: boolean;
  error?: string;
  data: any[];
  count: number;
  page: number;
  pageSize: number;
  filter?: any;
  sortBy?: ISortBy;
  columns: IColumn<any>[];
  collapsibleRowItemActions?: (column: string | any, val?: any)=>(e: any) => void;
  collapsibleRowItemValue?: (column: string | any, val?: any) => any;
  collapsibleRowRenderer?: (props: ICollapsibleRowProps<any>) => any;
  onRowClick?: (key: string) => (e: any) => void;
  onRefresh: (input: IGridRefreshInput) => void;
}

export const Grid = memo((props: IGridProps) => {
  const {
    columns,
    data,
    count,
    page,
    pageSize,
    filter,
    sortBy,
    loading,
    onRowClick,
    onRefresh,
    collapsibleRowItemActions,
    collapsibleRowItemValue,
    collapsibleRowRenderer
  } = props;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onRefresh({page: newPage, pageSize, filter, sortBy});
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newPageSize = parseInt(event.target.value, 10);
    onRefresh({page: 0, pageSize: newPageSize, filter, sortBy});
  };

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <GridHead columns={columns} collapsibleRowRenderer={collapsibleRowRenderer} />
          <TableBody>
            {}
            {loading
                ? (<EmptyGridBody colSize={columns.length}>Loading ...</EmptyGridBody>)
                : data.length === 0 ? (<EmptyGridBody colSize={columns.length}>Items Not Found</EmptyGridBody>)
                    : data.map((row, index) => {
                      return (
                          <GridRow
                              columns={columns}
                              key={index}
                              keyField="uid"
                              onRowClick={onRowClick}
                              row={row}
                              collapsibleRowItemActions={collapsibleRowItemActions}
                              collapsibleRowItemValue={collapsibleRowItemValue}
                              collapsibleRowRenderer={collapsibleRowRenderer}
                          />
                      );
                    })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
})