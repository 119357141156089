import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IBloc } from "../ioc";
import { ObservableValidateAutomate } from "../utils/observable-validation-automate";
import {
  ICountryService,
  CountryServiceSymbol,
  DialogServiceSymbol,
  IDialogService,
  UserServiceSymbol,
  IUserService,
  PriceListServiceSymbol,
  IPriceListService,
  RegionServiceSymbol,
  IRegionService,
} from "../services";
import { RegionInput, PriceList, Country, User, IFormField } from "../models";
import { ISelect } from "../components/select-input";
import {
  UserStoreSymbol,
  IUserStore,
  PriceListStoreSymbol,
  IPriceListStore,
  CountryStoreSymbol,
  ICountryStore,
} from "../stores";
import { AvailabilityStatus } from "../models";

interface IFormTextField {
  value?: string;
  error: string | null | undefined;
  onChange: (v: string | any) => void;
}

interface IRegionFormViewData {
  country: ISelect;
  status: ISelect;
  pricelist: ISelect;
  description: IFormTextField;
  zipCodes: IFormField<Array<string>>;
  coordinates: IFormField<{latitude: number, longitude: number} | undefined>;
  radius: IFormTextField;
  responsibleParty: ISelect;
}

export interface IRegionAddBloc extends IBloc<any> {
  formData: IRegionFormViewData;
  loading: boolean;
  loadingBtn: boolean;
  error: string | null;
  onSubmit(e?: any): void;
  onCancel(e?: any): void;
  onClose(e?: any): void;
}

const statuses: [string, string][] = [
  [AvailabilityStatus.Available, "Available"],
  [AvailabilityStatus.ComingSoon, "Coming Soon"],
  [AvailabilityStatus.Unavailable, "Unavailable"],
];

@injectable()
export class RegionAddBloc implements IRegionAddBloc {
  @inject(CountryServiceSymbol) countryService!: ICountryService;
  @inject(CountryStoreSymbol) countryStore!: ICountryStore;
  @inject(DialogServiceSymbol) dialogService!: IDialogService;
  @inject(UserServiceSymbol) userService!: IUserService;
  @inject(PriceListServiceSymbol) pricelistService!: IPriceListService;
  @inject(UserStoreSymbol) userStore!: IUserStore;
  @inject(PriceListStoreSymbol) priceListStore!: IPriceListStore;
  @inject(RegionServiceSymbol) regionService!: IRegionService;

  @observable private _loading: boolean = true;
  @observable private _loadingBtn: boolean = false;
  @observable private _error: string | null = null;

  @observable private _pricelists: PriceList[] = [];
  @observable private _countries: Country[] = [];
  @observable private _admins: User[] = [];

  @observable
  private _validator = new ObservableValidateAutomate(RegionInput);

  @observable
  private _errors: Partial<Record<keyof RegionInput, string | null | undefined>> = {
    country: null,
    status: null,
    pricelist: null,
    description: null,
    zipCodes: null,
    coordinates: null,
    radius: null,
    responsibleParty: null
  };

  constructor() {
    makeObservable(this);
  }

  mount(props: any): void {
    this.refreshSelectableFields();
  }

  @computed
  public get formData(): IRegionFormViewData {
    return {
      country: {
        id: "counrty",
        label: "Country",
        values: this.countrySelectableInput,
        value: this._validator.values.country ?? "",
        error: this._validator.errors.country,
        onChange: this._validator.update.country,
      },
      status: {
        id: "status",
        label: "Availability Status",
        values: statuses,
        value: this._validator.values.status ?? "",
        error: this._validator.errors.status,
        onChange: this._validator.update.status,
      },
      pricelist: {
        id: "pricelist",
        label: "Pricelist",
        values: this.pricelistSelectableInput,
        value: this._validator.values.pricelist ?? "",
        error: this._validator.errors.pricelist,
        onChange: this._validator.update.pricelist,
      },
      description: {
        value: this._validator.values.description ?? "",
        error: this._validator.errors.description,
        onChange: this._validator.update.description,
      },
      zipCodes: {
        value: this._validator.values.zipCodes ?? [],
        error: this._validator.errors.zipCodes,
        onChange: this._validator.update.zipCodes,
      },
      coordinates: {
        value: this._validator.values.coordinates ?? undefined,
        error: this._validator.errors.coordinates,
        onChange: this._validator.update.coordinates,
      },
      radius: {
        value: this._validator.values.radius?.toString() ?? "0",
        error: this._validator.errors.radius,
        onChange: this._validator.update.radius,
      },
      responsibleParty: {
        id: "responsibleParty",
        label: "Responsible Party",
        values: this.adminSelectableInput,
        value: this._validator.values.responsibleParty ?? "",
        error: this._validator.errors.responsibleParty,
        onChange: this._validator.update.responsibleParty,
      },
    };
  }
  @computed
  public get loading(): boolean {
    return this._loading;
  }

  @computed
  public get loadingBtn(): boolean {
    return this._loadingBtn;
  }

  @computed
  public get error(): string | null {
    return this._error;
  }

  @computed
  private get pricelistSelectableInput(): [string, string][] {
    return this._pricelists.map((pricelist) => {
      return [
        pricelist.uid,
        `${pricelist.currency} - ${pricelist.description}`,
      ];
    });
  }

  @computed
  private get countrySelectableInput(): [string, string][] {
    return this._countries.map((country) => {
      return [country.uid, `${country.country}`];
    });
  }

  @computed
  private get adminSelectableInput(): [string, string][] {
    return this._admins.map((admin) => {
      return [admin.uid, admin.email];
    });
  }

  @computed
  public get admins(): User[] {
    return this._admins;
  }

  public onSubmit = async (e: any) => {
    e.preventDefault();
    if (!this._validator.validate()) {
      return;
    }

    runInAction(() => (this._loadingBtn = true));

    try {
      const data = this._validator.read();
      await this.regionService.addRegion(data);
      this.dialogService.close();
    } catch (err: any) {
      throw new Error(err.message);
    } finally {
      runInAction(() => (this._loadingBtn = false));
      this._validator.reset();
    }
  };

  public onCancel = (e?: any) => {
    this.dialogService.close();
  };

  public onClose = (e?: any) => {
    this.dialogService.close();
  };

  private async refreshSelectableFields() {
    try {
      await this.userService.fetch(undefined, undefined, `role="admin"`);
      await this.pricelistService.fetch();
      await this.countryService.fetch();
    } catch (e: any) {
      runInAction(() => {
        this._error = e.message;
      });
    } finally {
      runInAction(() => {
        this._pricelists = this.priceListStore.data;
        this._countries = this.countryStore.data;
        this._admins = this.userStore.data;
      });
      if (
        this._pricelists.length === 0 ||
        this._admins.length === 0 ||
        this._countries.length === 0
      ) {
        runInAction(() => {
          this._error =
            "Error occured. Fetched empty data for obligatory select fields";
        });
      }
      runInAction(() => {
        this._validator.reset();
        this._loading = false;
      });
    }
  }
}
