export enum DialogType {
  DoctorUpdate = 'doctor-update',
  PriceListUpdate = 'price-list-update',
  PriceListAdd = 'price-list-add',
  CountryUpdate = 'country-update',
  CountryAdd = 'country-add',
  RegionUpdate = 'region-update',
  RegionAdd = 'region-add'
}

export interface DialogModel<T> {
  type: DialogType;
  props?: T;
}