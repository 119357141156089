import { inject, injectable } from "inversify";
import { NavigateFunction } from "react-router-dom";
import _ from "@ts-awesome/model-reader"

export interface INavigationService {
  navigate(path: string, params?: Record<string, string | undefined>): void;
  route(path: string, params?: Record<string, string | undefined>): string;
  getQueryParam(name: string, convertTo: typeof Date, nullable: true): Date | null;
  getQueryParam(name: string, convertTo: typeof Date, nullable: false): Date;
  getQueryParam<T extends Class>(name: string, constructor: [T], nullable: true): InstanceType<T>[] | null;
  getQueryParam<T extends Class>(name: string, constructor: [T], nullable: false): InstanceType<T>[];
  getQueryParam<T extends Class>(name: string, constructor: T, nullable: true): InstanceType<T> | null;
  getQueryParam<T extends Class>(name: string, constructor: T, nullable: false): InstanceType<T>;}

declare type Class = new (...args: any) => any;

export const NavigationServiceSymbol = Symbol.for('INavigationService');

@injectable()
export class NavigationService implements INavigationService {

  @inject(Symbol.for('navigate'))
  private reactNavigate!: NavigateFunction;

  public navigate(path: string, params?: Record<string, string | undefined> | undefined): void {
    const r = this.route(path, params);
    console.log('navigateTo', r);
    this.reactNavigate(r);
  }

  public route(path: string, params?: Record<string, string | undefined>): string {
    Object.entries(params ?? {}).forEach(([prop, value]) => {
      if (!value) return; 
      path = path.replaceAll(`:${prop}`, value);
    }); 
    return `${path.startsWith('/') ? '' : '/'}${path}`;
  }

  public get query(): Record<string, unknown> {
    return Object.fromEntries(
      window.location.search
        .substring(1)
        .split('&')
        .map(x => x
          .split('=')
          .map(decodeURIComponent)
          .map(x => x.trim())
        )
        .filter(([key]) => key)
        .map(([key, value]) => [key, value ?? null])
        // TODO: convert multiple entries with same name to array
    )
  }

  public getQueryParam(name: string, convertTo: typeof Date, nullable: true): Date | null;
  public getQueryParam(name: string, convertTo: typeof Date, nullable: false): Date;
  public getQueryParam<T extends Class>(name: string, constructor: [T], nullable: true): InstanceType<T>[] | null;
  public getQueryParam<T extends Class>(name: string, constructor: [T], nullable: false): InstanceType<T>[];
  public getQueryParam<T extends Class>(name: string, constructor: T, nullable: true): InstanceType<T> | null;
  public getQueryParam<T extends Class>(name: string, constructor: T, nullable: false): InstanceType<T>;
  public getQueryParam(name: string, Model: unknown, nullable = true): unknown {
    const strict = !nullable
    return _(this.query[name], Model as never, strict as never);
  }
}
