import React, { useCallback } from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export interface IUploader {
  id: string;
  label: string;
  value?: any;
  accept: string;
  onChange?(value: any): void;
  errorText?: string;
  defaultFilename?: string;
}

export const FileUploader = ({
  id,
  label,
  value,
  onChange,
  accept,
  errorText,
  defaultFilename,
}: IUploader) => {
  const handleChange = useCallback(
    ({ target }) => onChange?.(target.files[0]),
    [onChange]
  );

  return (
    <div>
      <div>{label}</div>
      <Stack direction="row" justifyContent="center" spacing={2}>
        <IconButton color="primary" aria-label="upload file" component="label">
          <input
            hidden
            id={id}
            onChange={handleChange}
            accept={accept}
            type="file"
          />
          <UploadFileIcon />
        </IconButton>
      </Stack>
      {value ? (
        <div style={{ fontSize: "0.75em" }}>{value?.name}</div>
      ) : defaultFilename ? (
        <div style={{ fontSize: "0.75em" }}>{defaultFilename}</div>
      ) : null}

      {errorText ? (
        <div style={{ color: "red", fontSize: "0.75em" }}>{errorText}</div>
      ) : undefined}
    </div>
  );
};
