import { Outlet } from "react-router-dom"

export function MainLayout() {
  return <>
    {/* <div style={{
      width: '100%',
      height: '60px',
      backgroundColor: 'darkred',
      color: 'white',
      fontSize: 40
    }}>Invia ADM</div> */}
    <Outlet/>
  </>
}
