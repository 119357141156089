import { inject, injectable } from "inversify";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { IBloc } from "./ioc";
import { DialogType } from "./models/dialog";
import { AuthServiceSymbol, IAuthService, INavigationService, IUserService, NavigationServiceSymbol, UserServiceSymbol } from "./services";
import { AuthStoreSymbol, DialogStoreSymbol, IAuthStore, IDialogStore } from "./stores";

export interface IAppBloc extends IBloc<any> {
  readonly authenticated: boolean;
  readonly loading: boolean;
  readonly doctorDialogOpen: boolean;
  readonly pricelistUpdateDialogOpen: boolean;
  readonly pricelistAddDialogOpen: boolean;
  readonly countryAddDialogOpen: boolean;
  readonly countryUpdateDialogOpen: boolean;
  readonly regionAddDialogOpen: boolean;
  readonly regionUpdateDialogOpen: boolean;
  readonly dialogProps: any;
  onSignOut(e?: any): void;
}

@injectable()
export class AppBloc implements IAppBloc {

  @inject(AuthStoreSymbol) authStore!: IAuthStore;
  @inject(AuthServiceSymbol) authService!: IAuthService;
  @inject(UserServiceSymbol) userService!: IUserService;
  @inject(NavigationServiceSymbol) navigationService!: INavigationService;

  @inject(DialogStoreSymbol) dialogStore!: IDialogStore;

  @observable private _loading = true;

  constructor() {
    makeObservable(this);
  }

  @computed get authenticated(): boolean {
    return this.authStore.isAuth;
  }

  @computed get loading(): boolean {
    return this._loading;
  }

  @computed
  public get doctorDialogOpen(): boolean {
    return this.dialogStore.data?.type === DialogType.DoctorUpdate && this.dialogStore.open;
  }

  @computed
  public get pricelistUpdateDialogOpen(): boolean {
    return this.dialogStore.data?.type === DialogType.PriceListUpdate && this.dialogStore.open;
  }
  @computed
  public get pricelistAddDialogOpen(): boolean {
    return this.dialogStore.data?.type === DialogType.PriceListAdd && this.dialogStore.open;
  }
  @computed
  public get countryAddDialogOpen(): boolean {
    return this.dialogStore.data?.type === DialogType.CountryAdd && this.dialogStore.open;
  }
  @computed
  public get countryUpdateDialogOpen(): boolean {
    return this.dialogStore.data?.type === DialogType.CountryUpdate && this.dialogStore.open;
  }
  @computed
  public get regionAddDialogOpen(): boolean {
    return this.dialogStore.data?.type === DialogType.RegionAdd && this.dialogStore.open;
  }
  @computed
  public get regionUpdateDialogOpen(): boolean {
    return this.dialogStore.data?.type === DialogType.RegionUpdate && this.dialogStore.open;
  }
  @computed
  public get dialogProps(): any {
    return this.dialogStore.data?.props;
  }

  mount?(props: any): void {
    this.promisifiedMount(props);
  }

  private async promisifiedMount(props: any): Promise<void> {
    if (this.authStore.isAuth) {
      try {
        await this.userService.loadMe();
      } catch(err) {
        console.error(err);
      }
    }
    runInAction(() => {
      this._loading = false;
    });
  }
 
  onSignOut = (e: any) => {
    this.authService.singOut();
    this.navigationService.navigate('/singin');
  };
}