import { injectable } from "inversify";
import { observable, action, makeObservable, computed } from 'mobx';
import { User } from '../models';
import { mergeBy } from "../utils";

export interface IUserStore {
  readonly data: User[];
  readonly count: number;
  
  refresh(data: readonly User[]): void;
  reset(data: readonly User[], count: number): void;
  find(uid: string): User | null;
  remove(uid: string): void;
}

export const UserStoreSymbol = Symbol.for('IUserStore')

@injectable()
export class UserStore implements IUserStore {

  @observable
  private _data: User[];

  @observable
  private _count: number;

  public constructor() {
    this._data = [];
    this._count = 0;
    makeObservable(this)
  }

  @computed get data(): User[] {
    return this._data;
  }

  @computed get count(): number {
    return this._count;
  }

  @action
  public refresh(data: readonly User[]): void {
    const oldDataCount = this._data.length;
    this._data = mergeBy(({uid}) => uid, this.data, data);
    if (this._data.length > oldDataCount) {
      this._count++;
    }
  }

  @action
  public reset(data: readonly User[], count: number): void {
    this._data = [...data];
    this._count = count;
  }

  @action
  public find(uid: string): User | null {
    return this._data.find(_ => _.uid === uid) ?? null;
  }

  @action
  public remove(uid: string): void {
    this._data = this._data.filter(_ => _.uid !== uid);
    this._count--;
  }
}