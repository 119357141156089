import { inject, injectable } from "inversify";
import { ICountryApi, CountryApiSymbol } from "../api";
import { CountryInput, PatchCountryInput, IFetchFilter, ISortBy } from "../models";
import { ICountryStore, CountryStoreSymbol } from "../stores";

export interface ICountryService {
  fetch(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<void>;
  updateCountry(uid: string, input: PatchCountryInput): Promise<void>;
  addCountry(input: CountryInput): Promise<void>;
}

export const CountryServiceSymbol = Symbol.for("ICountryService");

@injectable()
export class CountryService implements ICountryService {
  @inject(CountryApiSymbol)
  private countryApi!: ICountryApi;

  @inject(CountryStoreSymbol)
  private countryStore!: ICountryStore;

  public async fetch(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<void> {
    const [data, count] = await Promise.all([
      this.countryApi.list(start, limit, filter, sortBy),
      this.countryApi.count(filter),
    ]);
    this.countryStore.reset(data, count);
  }

  public async updateCountry(
    uid: string,
    input: PatchCountryInput
  ): Promise<void> {
    const country = await this.countryApi.patchCountry(uid, input);
    this.countryStore.refresh([country]);
  }

  public async addCountry(input: CountryInput): Promise<void>{
    const country = await this.countryApi.postCountry(input)
    this.countryStore.refresh([country]);
  }
}