import { inject, injectable } from "inversify";
import { IFetchFilter, ISortBy, Region, RegionInput } from "../models";
import { HttpTransportSymbol, IHttpTransport } from "@ts-awesome/request";

export interface IRegionApi {
  list(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<readonly Region[]>;
  count(filter?: IFetchFilter): Promise<number>;
  getRegion(uid: string): Promise<Region>;
  putRegion(uid: string, input: RegionInput): Promise<Region>;
  postRegion(input: RegionInput): Promise<Region>;
  deleteRegion(uid: string): Promise<void>;
}

export const RegionApiSymbol = Symbol.for("IRegionApi");

@injectable()
export class RegionApi implements IRegionApi {
  @inject(HttpTransportSymbol)
  private readonly http!: IHttpTransport;

  public async list(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<readonly Region[]> {
    const qs: Record<string, string | number> = {};
    if (start) {
      qs.offset = start;
    }
    if (limit) {
      qs.limit = limit;
    }
    if (filter) {
      qs.q = filter;
    }
    if (sortBy) {
      qs.order = Object.keys(sortBy)
        .map((col) => `${col}${sortBy[col] === "desc" ? "-" : "+"}`)
        .join(",");
    }
    return (await this.http.get("/api/v1/region", {
      qs,
      Model: [Region],
    })) as never;
  }

  public count(filter?: IFetchFilter): Promise<number> {
    const qs: Record<string, string | number | boolean> = { count: true };
    if (filter) {
      qs.q = filter;
    }
    return this.http.get("/api/v1/region", { qs });
  }

  public getRegion(uid: string): Promise<Region> {
    return this.http.get(`/api/v1/region/${uid}`, { Model: Region });
  }
  
  public async putRegion(
    uid: string,
    input: RegionInput
  ): Promise<Region> {
    if (!uid) {
      throw new Error("Uid is missing");
    }
    return this.http.put<Region>(`/api/v1/region/${uid}`, {
      body: { ...input },
      Model: Region,
    });
  }

  public async postRegion(input: RegionInput): Promise<Region> {
    return this.http.post<Region>(`/api/v1/region`, {
      body: { ...input },
      Model: Region,
    });
  }

  public async deleteRegion(uid: string): Promise<void> {
    return this.http.delete(`/api/v1/region/${uid}`);
  }
}