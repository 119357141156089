import { readable } from "@ts-awesome/model-reader";

export class PriceList {
  @readable(String) public uid!: string;
  @readable(Date) public createdAt!: Date;
  @readable(String) public createdBy!: string;
  @readable(Date) public updatedAt!: Date;
  @readable(String) public updatedBy!: string;
  @readable(Number, true) public pediatricianPrice!: number | null;
  @readable(Number, true) public familyInternistPrice!: number | null;
  @readable(Number, true) public entPrice!: number | null;
  @readable(Number, true) public woundCarePrice!: number | null;
  @readable(Number, true) public surgeonPrice!: number | null;
  @readable(Number, true) public painManagementPrice!: number | null;
  @readable(Number, true) public obGynPrice!: number | null;
  @readable(Number, true) public psychologyPrice!: number | null;
  @readable(String) public currency!: string;
  @readable(String) public description!: string;
  @readable(Number) public cancellationFee!: number;
  @readable(Number) public serviceFee!: number;
}
