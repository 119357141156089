import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import {Container, ContainerModule, interfaces} from 'inversify';
import { NavigateFunction } from 'react-router-dom';

let container: Container

export function setContainer(iocContainer: Container): void {
  container = iocContainer
}

export function bind<T>(identifier: string | symbol): interfaces.BindingToSyntax<T> {
  return container.bind<T>(identifier);
}

export function getFromIoC<T>(klass: interfaces.Newable<T>): T {
  return container.resolve(klass);
}
export interface IBloc<T> {
  mount?(props: T): void;
  unmount?(): void;
}

export function useIoC<T>(ctor: interfaces.Newable<T>, ...modules: ContainerModule[]): T {
  const [instance] = useState(() => {
    const child = container.createChild()
    if (modules) {
      child.load(...modules)
    }
    return child.resolve<T>(ctor)
  });
  return instance;
}

export const NavigateSymbol = Symbol.for('navigate');

export function useBloc<T extends IBloc<unknown>>(ctor: interfaces.Newable<T>, props: unknown): T {
  const navigate = useNavigate();
  
  setNavigation(navigate);

  const bloc = useIoC<T>(ctor,
      new ContainerModule((bind) => {
        bind<NavigateFunction>(NavigateSymbol).toFunction(navigate);
      })
    );

  useEffect(() => {
    bloc.mount?.(props);

    return () => {
      bloc.unmount?.();
    };
  }, [bloc, props]);

  return bloc;
}

export function setNavigation(navigate: NavigateFunction): void {
  const navigateSymbol = Symbol.for('navigate');
  if (!container.isBound(navigateSymbol)) {
    container.bind<NavigateFunction>(navigateSymbol).toFunction(navigate);
  }
}