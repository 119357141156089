import {memo} from 'react';
import { Outlet, Link } from 'react-router-dom'
import { Observer } from 'mobx-react-lite'
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useRouteMatch } from '../../../utils/use-route-match';

enum UserRoutes {
  Patients = '/users/patients',
  Doctors = '/users/doctors',
}

export const UsersHeader = memo((props: any) => {

  const routeMatch = useRouteMatch([UserRoutes.Patients, UserRoutes.Doctors]);
  const currentTab = routeMatch?.pattern?.path ?? UserRoutes.Patients;

  return (
      <Observer>
        {() => {
          return (
              <Box sx={{width: '100%'}}>
                  <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={currentTab}>
                      <Tab label="Patients" value={UserRoutes.Patients} to={UserRoutes.Patients} component={Link}/>
                      <Tab label="Doctors" value={UserRoutes.Doctors} to={UserRoutes.Doctors} component={Link}/>
                    </Tabs>
                  </Box>
                <Outlet />
              </Box>
          )
        }}
      </Observer>
  )
})