import React, { memo } from "react";
import { Observer } from "mobx-react-lite";
import { useBloc } from "../ioc";
import { DoctorUpdateBloc } from "./doctor-update.bloc";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";

export const DoctorUpdateDialog = memo((props) => {
  const bloc = useBloc(DoctorUpdateBloc, props);
  return (
    <Observer>
      {() => {
        const { formData, loading, onClose, onSubmit, onCancel, doctorAlreadyApproved } = bloc;
        return (
          <Dialog open={true} onClose={onClose}>
            <DialogTitle> Doctor Info </DialogTitle>
            <Box component="form" onSubmit={onSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField
                    id="firstName"
                    label="First Name"
                    error={!!formData.firstName.error}
                    helperText={formData.firstName.error || ""}
                    onChange={formData.firstName.onChange}
                    value={formData.firstName.value}
                  />
                  <TextField
                    id="lastName"
                    label="Last Name"
                    error={!!formData.lastName.error}
                    helperText={formData.lastName.error || ""}
                    onChange={formData.lastName.onChange}
                    value={formData.lastName.value}
                  />
                  <TextField
                    id="speciality"
                    label="Speciality"
                    type="speciality"
                    error={!!formData.speciality.error}
                    helperText={formData.speciality.error || ""}
                    onChange={formData.speciality.onChange}
                    value={formData.speciality.value}
                  />
                  <FormControlLabel
                    control={
                    <Checkbox 
                      id="approved"
                      onChange={formData.approved.onChange}
                      checked={formData.approved.checked}
                      disabled={doctorAlreadyApproved}
                      />
                    }
                    label="Qualification Checked And Verified"
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  type="submit"
                  fullWidth
                >
                  Submit
                </LoadingButton>
                <Button onClick={onCancel}>Cancel</Button>
              </DialogActions>
            </Box>
          </Dialog>
        );
      }}
    </Observer>
  );
});