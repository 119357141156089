import React, { memo, useCallback, useState } from "react";
import { TextField as MaterialTextField, TextFieldProps } from "@mui/material";
import { IFormField } from "../models";

export type InputProps<T = Array<string>> = IFormField<T> &
  Omit<TextFieldProps, "error" | "onChangeText" | "onChange" | "onTextInput">;

export const ArrayTextField = memo<InputProps>((props) => {
  const { value, error, onChange, style, ...otherProps } = props;
  const [val, setVal] = useState(value?.join(','))

  const handleChange = useCallback(
    (e: any) => {
      setVal(e.target.value)
      onChange?.(e.target.value.split(",").map(e => e.trim()).filter(e=> e !== ''))
    },
    [onChange]
  );

  return (
    <MaterialTextField
      error={!!error}
      helperText={error || ""}
      onChange={handleChange}
      value={val ?? ""}
      {...otherProps}
    />
  );
});
