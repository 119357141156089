import { inject, injectable } from "inversify";
import {
  IFetchFilter,
  ISortBy,
  Country,
  CountryInput,
  PatchCountryInput,
} from "../models";
import { HttpTransportSymbol, IHttpTransport } from "@ts-awesome/request";

export interface ICountryApi {
  list(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<readonly Country[]>;
  count(filter?: IFetchFilter): Promise<number>;
  getCountry(uid: string): Promise<Country>;
  patchCountry(uid: string, input: PatchCountryInput): Promise<Country>;
  postCountry(input: CountryInput): Promise<Country>;
  deleteCountry(uid: string): Promise<void>;
}

export const CountryApiSymbol = Symbol.for("ICountryApi");

@injectable()
export class CountryApi implements ICountryApi {
  @inject(HttpTransportSymbol)
  private readonly http!: IHttpTransport;

  public async list(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<readonly Country[]> {
    const qs: Record<string, string | number> = {};
    if (start) {
      qs.offset = start;
    }
    if (limit) {
      qs.limit = limit;
    }
    if (filter) {
      qs.q = filter;
    }
    if (sortBy) {
      qs.order = Object.keys(sortBy)
        .map((col) => `${col}${sortBy[col] === "desc" ? "-" : "+"}`)
        .join(",");
    }
    return (await this.http.get("/api/v1/country", {
      qs,
      Model: [Country],
    })) as never;
  }

  public count(filter?: IFetchFilter): Promise<number> {
    const qs: Record<string, string | number | boolean> = { count: true };
    if (filter) {
      qs.q = filter;
    }
    return this.http.get("/api/v1/country", { qs });
  }

  public getCountry(uid: string): Promise<Country> {
    return this.http.get(`/api/v1/country/${uid}`, { Model: Country });
  }

  public async patchCountry(
    uid: string,
    input: PatchCountryInput
  ): Promise<Country> {
    if (!uid) throw new Error("Uid is missing");

    const {
      country,
      status,
      pricelist,
      emergencyPhone,
      doctorTemplate,
      patientTemplate,
      childTemplate,
      responsibleParty,
      stripePublicKey,
      stripeSecretKey,
      stripeWebhookSecret,
    } = input;

    const body = new FormData();
    body.append("country", country);
    body.append("status", status);
    body.append("pricelist", pricelist);
    body.append("emergencyPhone", emergencyPhone);
    if (doctorTemplate) {
      body.append("doctorTemplate", doctorTemplate, doctorTemplate.name);
    }
    if (patientTemplate) {
      body.append("patientTemplate", patientTemplate, patientTemplate.name);
    }
    if (childTemplate) {
      body.append("childTemplate", childTemplate, childTemplate.name);
    }
    body.append("responsibleParty", responsibleParty);
    body.append("stripePublicKey", stripePublicKey);
    body.append("stripeSecretKey", stripeSecretKey);
    body.append("stripeWebhookSecret", stripeWebhookSecret);

    return this.http.patch<Country>(`/api/v1/country/${uid}`, { body, Model: Country });
  }

  public async postCountry({
    country,
    status,
    pricelist,
    emergencyPhone,
    doctorTemplate,
    patientTemplate,
    childTemplate,
    responsibleParty,
    stripePublicKey,
    stripeSecretKey,
    stripeWebhookSecret,
  }: CountryInput): Promise<Country> {
    const body = new FormData();
    body.append("country", country);
    body.append("status", status);
    body.append("pricelist", pricelist);
    body.append("emergencyPhone", emergencyPhone);
    body.append("doctorTemplate", doctorTemplate, doctorTemplate.name);
    body.append("patientTemplate", patientTemplate, patientTemplate.name);
    body.append("childTemplate", childTemplate, childTemplate.name);
    body.append("responsibleParty", responsibleParty);
    body.append("stripePublicKey", stripePublicKey);
    body.append("stripeSecretKey", stripeSecretKey);
    body.append("stripeWebhookSecret", stripeWebhookSecret);

    return this.http.post<Country>(`/api/v1/country`, { body, Model: Country });
  }

  public async deleteCountry(uid: string): Promise<void> {
    return this.http.delete(`/api/v1/country/${uid}`);
  }
}
