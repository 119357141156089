import { Container } from 'inversify'

import { IHttpTransport, HttpTransportSymbol } from '@ts-awesome/request';
import { AuthStore, AuthStoreSymbol, ConsultationStore, ConsultationStoreSymbol, DialogStore, DialogStoreSymbol, IAuthStore, IConsultationStore, IDialogStore, IMeUserStore, INotificationStore, IUserStore, MeUserStore, MeUserStoreSymbol, NotificationStore, NotificationStoreSymbol, UserStore, UserStoreSymbol, PriceListStore, IPriceListStore, PriceListStoreSymbol, CountryStore, CountryStoreSymbol, ICountryStore, IAttachmentStore, AttachmentStoreSymbol, AttachmentStore, IRegionStore, RegionStoreSymbol, RegionStore } from '../stores';
import { AdminHttpTransport } from '../utils/transport';
import { IAuthApi, AuthApiSymbol, AuthApi, IUserApi, UserApiSymbol, UserApi, IConsultationApi, ConsultationApiSymbol, ConsultationApi, IPriceListApi, PriceListApiSymbol, PriceListApi, CountryApi, ICountryApi, CountryApiSymbol, IAttachmentApi, AttachmentApiSymbol, AttachmentApi, IRegionApi, RegionApiSymbol, RegionApi } from '../api';
import { AuthService, AuthServiceSymbol, ConsultationService, ConsultationServiceSymbol, DialogService, DialogServiceSymbol, IAuthService, IConsultationService, IDialogService, INavigationService, INotificationService, IUserService, NavigationService, NavigationServiceSymbol, NotificationService, NotificationServiceSymbol, UserService, UserServiceSymbol, IPriceListService, PriceListService, PriceListServiceSymbol, CountryService, CountryServiceSymbol, ICountryService, IAttachmentService, AttachmentServiceSymbol, AttachmentService, IRegionService, RegionServiceSymbol, RegionService } from '../services';

// import {History, createBrowserHistory} from 'history';

export function setUpRootIoC(container: Container): void {

  console.log('envs', JSON.stringify(process.env, null, 10));
  console.log('process.env.REACT_APP_BASE_API_URL', process.env.REACT_APP_BASE_API_URL);
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL ?? window.location.origin;
  // const BASE_API_URL = '';//http://localhost:3000';

  // const history = createBrowserHistory();
  // container.bind<History>(Symbol.for('history')).toConstantValue(history);

  container.bind<IHttpTransport>(HttpTransportSymbol).toDynamicValue(({container}) => {
    const authStore = container.get<IAuthStore>(AuthStoreSymbol);
    return new AdminHttpTransport(
      console,
      BASE_API_URL,
      () => `Bearer ${authStore.token}`,
      () => authStore.clean()
    );
  });
  
  //API
  container.bind<IAuthApi>(AuthApiSymbol).to(AuthApi);
  container.bind<IUserApi>(UserApiSymbol).to(UserApi);
  container.bind<IConsultationApi>(ConsultationApiSymbol).to(ConsultationApi);
  container.bind<IPriceListApi>(PriceListApiSymbol).to(PriceListApi);
  container.bind<ICountryApi>(CountryApiSymbol).to(CountryApi);
  container.bind<IAttachmentApi>(AttachmentApiSymbol).to(AttachmentApi);
  container.bind<IRegionApi>(RegionApiSymbol).to(RegionApi); 
  
  // STORE BIND
  container.bind<IAuthStore>(AuthStoreSymbol).to(AuthStore).inSingletonScope();
  container.bind<IMeUserStore>(MeUserStoreSymbol).to(MeUserStore).inSingletonScope();
  container.bind<IUserStore>(UserStoreSymbol).to(UserStore).inSingletonScope();
  container.bind<IConsultationStore>(ConsultationStoreSymbol).to(ConsultationStore).inSingletonScope();
  container.bind<INotificationStore>(NotificationStoreSymbol).to(NotificationStore).inSingletonScope(); 
  container.bind<IDialogStore>(DialogStoreSymbol).to(DialogStore).inSingletonScope(); 
  container.bind<IPriceListStore>(PriceListStoreSymbol).to(PriceListStore).inSingletonScope();
  container.bind<ICountryStore>(CountryStoreSymbol).to(CountryStore).inSingletonScope();
  container.bind<IAttachmentStore>(AttachmentStoreSymbol).to(AttachmentStore).inSingletonScope();
  container.bind<IRegionStore>(RegionStoreSymbol).to(RegionStore).inSingletonScope(); 

  //SERVICES
  container.bind<IAuthService>(AuthServiceSymbol).to(AuthService);
  container.bind<IUserService>(UserServiceSymbol).to(UserService);
  container.bind<IConsultationService>(ConsultationServiceSymbol).to(ConsultationService);
  container.bind<INotificationService>(NotificationServiceSymbol).to(NotificationService);
  container.bind<INavigationService>(NavigationServiceSymbol).to(NavigationService);
  container.bind<IDialogService>(DialogServiceSymbol).to(DialogService);
  container.bind<IPriceListService>(PriceListServiceSymbol).to(PriceListService);
  container.bind<ICountryService>(CountryServiceSymbol).to(CountryService);
  container.bind<IAttachmentService>(AttachmentServiceSymbol).to(AttachmentService);
  container.bind<IRegionService>(RegionServiceSymbol).to(RegionService);
}

