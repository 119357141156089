import { inject, injectable } from "inversify";
import { IFetchFilter, ISortBy, PriceList, PriceListInput } from "../models";
import { HttpTransportSymbol, IHttpTransport } from "@ts-awesome/request";

export interface IPriceListApi {
  list(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<readonly PriceList[]>;
  count(filter?: IFetchFilter): Promise<number>;
  getPriceList(uid: string): Promise<PriceList>;
  putPriceList(uid: string, input: PriceListInput): Promise<PriceList>;
  postPriceList(input: PriceListInput): Promise<PriceList>;
  deletePriceList(uid: string): Promise<void>;
}

export const PriceListApiSymbol = Symbol.for("IPriceListApi");

@injectable()
export class PriceListApi implements IPriceListApi {
  @inject(HttpTransportSymbol)
  private readonly http!: IHttpTransport;

  public async list(
    start?: number,
    limit?: number,
    filter?: IFetchFilter,
    sortBy?: ISortBy
  ): Promise<readonly PriceList[]> {
    const qs: Record<string, string | number> = {};
    if (start) {
      qs.offset = start;
    }
    if (limit) {
      qs.limit = limit;
    }
    if (filter) {
      qs.q = filter;
    }
    if (sortBy) {
      qs.order = Object.keys(sortBy)
        .map((col) => `${col}${sortBy[col] === "desc" ? "-" : "+"}`)
        .join(",");
    }
    return (await this.http.get("/api/v1/pricelist", {
      qs,
      Model: [PriceList],
    })) as never;
  }

  public count(filter?: IFetchFilter): Promise<number> {
    const qs: Record<string, string | number | boolean> = { count: true };
    if (filter) {
      qs.q = filter;
    }
    return this.http.get("/api/v1/pricelist", { qs });
  }

  public getPriceList(uid: string): Promise<PriceList> {
    return this.http.get(`/api/v1/pricelist/${uid}`, { Model: PriceList });
  }
  
  public async putPriceList(
    uid: string,
    input: PriceListInput
  ): Promise<PriceList> {
    if (!uid) {
      throw new Error("Uid is missing");
    }
    return this.http.put<PriceList>(`/api/v1/pricelist/${uid}`, {
      body: { ...input },
      Model: PriceList,
    });
  }

  public async postPriceList(input: PriceListInput): Promise<PriceList> {
    return this.http.post<PriceList>(`/api/v1/pricelist`, {
      body: { ...input },
      Model: PriceList,
    });
  }

  public async deletePriceList(uid: string): Promise<void> {
    return this.http.delete(`/api/v1/pricelist/${uid}`);
  }
}
