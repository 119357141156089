import {memo} from 'react';
import { Outlet, Link } from 'react-router-dom'
import { Observer } from 'mobx-react-lite'
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useRouteMatch } from '../../../utils/use-route-match';

enum ConsultationsRoutes {
  Active = '/consultations/active',
  Finished = '/consultations/finished',
  WaitingMap = '/consultations/waiting-map'
}

export const ConsultatoionsHeader = memo((props: any) => {

  const routeMatch = useRouteMatch([ConsultationsRoutes.Active, ConsultationsRoutes.Finished, ConsultationsRoutes.WaitingMap]);
  const currentTab = routeMatch?.pattern?.path ?? ConsultationsRoutes.Active;

  return (
      <Observer>
        {() => {
          return (
              <Box sx={{width: '100%'}}>
                  <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={currentTab}>
                      <Tab label="Active Consultations" value={ConsultationsRoutes.Active} to={ConsultationsRoutes.Active} component={Link}/>
                      <Tab label="Finished Consultations" value={ConsultationsRoutes.Finished} to={ConsultationsRoutes.Finished} component={Link}/>
                      <Tab label="Waiting Map" value={ConsultationsRoutes.WaitingMap} to={ConsultationsRoutes.WaitingMap} component={Link}/>
                    </Tabs>
                  </Box>
                <Outlet />
              </Box>
          )
        }}
      </Observer>
  )
})