export enum MedicalCareType {
  Pediatrician = 'pediatrician',
  FamilyInternist = 'family_internist',
  ENT = 'ent', //Ear Nose Throat
  WoundCare = 'wound_care',
  Surgeon = 'surgeon',
  PainManagement = 'pain_management',
  OBGYN = 'ob_gyn',
  Psychology = 'psychology',
}

export const MedicalCareTypes = [
  MedicalCareType.Pediatrician,
  MedicalCareType.FamilyInternist,
  MedicalCareType.ENT,
  MedicalCareType.WoundCare,
  MedicalCareType.Surgeon,
  MedicalCareType.PainManagement,
  MedicalCareType.OBGYN,
  MedicalCareType.Psychology,
]
