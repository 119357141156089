import { injectable, inject } from "inversify";
import { NotificationType } from "../models";
import { INotificationStore, NotificationStoreSymbol } from "../stores";

export interface INotificationService {
  success(message: string): void;
  info(message: string): void;
  warning(message: string): void;
  error(message: string): void;
}

export const NotificationServiceSymbol = Symbol.for('INotificationService')

@injectable()
export class NotificationService implements INotificationService {

  @inject(NotificationStoreSymbol) store!: INotificationStore;


  public success(message: string): void {
    this.store.set({message, type: NotificationType.Success});
  }
  public info(message: string): void {
    this.store.set({message, type: NotificationType.Info});
  }
  public warning(message: string): void {
    this.store.set({message, type: NotificationType.Warning});
  }
  public error(message: string): void {
    this.store.set({message, type: NotificationType.Error});
  }
}