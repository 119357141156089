import { memo } from "react";
import { useBloc } from "../../../ioc";
import { PatientsBloc } from "./patients.bloc";
import { Grid, ICollapsibleRowProps } from "../../../components/grid";
import { Observer } from "mobx-react-lite";
import { User } from "../../../models";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

const collapsibleRowRenderer = ({ row }: ICollapsibleRowProps<User>) => {
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Details
      </Typography>
      <Table size="small" aria-label="patients">
        <TableHead>
          <TableRow>
            <TableCell key="firstName">First Name</TableCell>
            <TableCell key="lastName">Last Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.patients ? (
            row.patients.map((patient) => (
              <TableRow key={patient.uid}>
                <TableCell key="firstName">{patient.firstName}</TableCell>
                <TableCell key="lastName">{patient.lastName}</TableCell>
              </TableRow>
            ))
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export const PatientsPage = memo((props: any) => {
  const bloc = useBloc(PatientsBloc, { ...props });

  return (
    <Observer>
      {() => {
        const { gridData } = bloc;
        let grid = { ...gridData };
        grid.collapsibleRowRenderer = collapsibleRowRenderer;

        return <Grid {...grid} />;
      }}
    </Observer>
  );
});
