import { validate, presence, email, length } from '@ts-awesome/validate';
import { readable } from '@ts-awesome/model-reader';

export class SignInInput {

  @validate([presence(), email()])
  @readable(String)
  public email!: string;

  @validate([presence(), length({ minimum: 1 })])
  @readable(String)
  public password!: string;
}