import { memo } from "react";
import { useBloc } from "../../../ioc";
import { DoctorsBloc } from "./doctors.bloc";
import { Grid } from "../../../components/grid";
import { Observer } from "mobx-react-lite";
import Button from "@mui/material/Button";

export const DoctorsPage = memo((props: any) => {
  const bloc = useBloc(DoctorsBloc, { ...props });

  return (
    <Observer>
      {() => {
        const { gridData } = bloc;
        let grid = { ...gridData };

        grid.columns = [
          ...grid.columns,
          {
            field: "actions",
            headerName: "Actions",
            renderer: ({ row }) => {
              return (
                <>
                  <Button onClick={bloc.onDoctorEdit(row.uid)}>Edit</Button>
                </>
              );
            },
          },
        ];

        return <Grid {...grid} />;
      }}
    </Observer>
  );
});
