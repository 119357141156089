import { memo } from "react";
import { useBloc } from "../../../ioc";
import { ActiveConsultationsBloc } from "./active-consultations.bloc";

import { Grid } from "../../../components/grid";
import { Observer } from "mobx-react-lite";

export const ActiveConsultationsPage = memo((props: any) => {
  const bloc = useBloc(ActiveConsultationsBloc, { ...props });

  return (
    <Observer>
      {() => {
        const { gridData } = bloc;

        return <Grid {...gridData} />;
      }}
    </Observer>
  );
});
