import React, { memo } from 'react'
import { Observer } from 'mobx-react-lite'
import {useBloc} from '../../ioc'
import { SignInBloc } from './signin.bloc'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import {Button} from '../../components/button';
import {TextField} from '../../components/text-field';

export const SignInPage = memo(props => {
  const bloc = useBloc(SignInBloc, props)
  return (
    <Observer>
      {() => (
        <Container style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}>
          <Card sx={{ minWidth: 600 }}>
          <Box component="form" onSubmit={bloc.onSignIn}>
            <CardHeader title="Sign In">
            </CardHeader>
            <CardContent>
              <Stack spacing={2}>
                <TextField
                  id="email"
                  label="Email"
                  {...bloc.formData.email}
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  {...bloc.formData.password}
                />
                <Link href="/singup" underline="none">
                  Create new account
                </Link>
                <Link href="/forgot-password" underline="none">
                  I forgot password
                </Link>
              </Stack>
            </CardContent>
            <CardActions>
              <Button
                loading={bloc.loading}
                type="submit"
              >
                Sing In
              </Button>
            </CardActions>
          </Box>
          </Card>
        </Container>
      )}
    </Observer>
  )
})